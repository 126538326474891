import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import Pagination from 'react-bootstrap/Pagination';
import axios from 'axios';
import Lottie from 'react-lottie';
import loadingAnimation from './load.json';
import { Button, Skeleton, styled } from '@mui/material';

const ShinySkeleton = styled(Skeleton)(({ theme }) => ({
  background: 'linear-gradient(90deg, rgba(50,50,50,0.05) 25%, rgba(50,50,50,0.15) 50%, rgba(50,50,50,0.05) 75%)',
  backgroundSize: '200% 100%',
  animation: 'shine 1.5s infinite',
  '@keyframes shine': {
    '0%': {
      backgroundPosition: '-200% 0',
    },
    '100%': {
      backgroundPosition: '200% 0',
    },
  },
}));


const Movimento = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50000);
  const [searchTerm, setSearchTerm] = useState("");
  const [load, setLoad] = useState(false);
  const [movimento, setMovimento] = useState(props.movimento);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [carregamento, setCarregamento] = useState(false)

function carregarTodas() {
    const fetchMovimento = async () => {
      setLoad(true); // Atualiza o estado para indicar que os dados foram carregados
      try {
        
        const response = await axios.get('https://alertadiarioes.com/apicdpv2/get_movimento_cdpg_full.php');
        setMovimento(response.data);
        setCarregamento(true)
        setLoad(false); // Atualiza o estado para indicar que os dados foram carregados
      } catch (error) {
        console.error('Erro ao buscar os dados do movimento:', error);
        setLoad(false);
      }
    };
  
    fetchMovimento();
  
}
  const paginationItems = [...Array(Math.ceil(movimento.length / itemsPerPage))].map((_, index) => (
    <Pagination.Item
      key={index}
      active={index + 1 === currentPage}
      onClick={() => paginate(index + 1)}
    >
      {index + 1}
    </Pagination.Item>
  ));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  };



  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleSearchTermChange = e => setSearchTerm(e.target.value);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = movimento.slice(indexOfFirstItem, indexOfLastItem);

  const filteredItems = currentItems.filter(item =>
    item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.id.toString().includes(searchTerm) ||
    item.tipo.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.cela.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.infopen.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.dataRegistro.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.usuario.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <br />
      <center>
        <h1 style={{ marginTop: 50 }}><strong>ATIVIDADES RECENTES</strong></h1>
        <h5><b>RELATÓRIO DE MOVIMENTAÇÕES</b></h5>
        <br />
        
        <Input
          type="text"
          placeholder="Buscar Movimentação..."
          value={searchTerm}
          onChange={handleSearchTermChange}
          style={{ width: "45%", marginBottom: "20px", marginTop: 30, fontSize: 22, border: "2px solid #545f34" }}
        />
        <br />
       
        
        <div style={{ width: "85%", borderRadius: "25px" }}>
          <div>
            <div style={styles.container}>
             
            {load && 
              
            
              
  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection:'column', width:'100%' }}>
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />
    <ShinySkeleton variant="text" width="100%" height={60} />

  </div>
}
              {!load && (
                <div>
                  <div style={styles.row}>
                    <div style={styles.columnC}></div>
                    <div style={styles.columnCnome}>NOME</div>
                    <div style={styles.columnC}>CELA</div>
                    <div style={styles.columnC}>REGISTRO EM</div>
                    <div style={styles.columnC}>USUÁRIO</div>
                  </div>
                  {filteredItems.map((mov, index) => (
                    <div key={index} style={styles.row}>
                      <div style={{ ...styles.column3, ...(mov.tipo === 'ENTRADA' ? styles.green : mov.tipo === 'SAÍDA' ? styles.red : styles.gray) }}><b>{mov.tipo} {mov.motivoSaida}</b></div>
                      <div style={styles.nome}>{mov.nome}</div>
                      <div style={styles.column}><b>{mov.cela}</b></div>
                      <div style={styles.column}>{mov.dataRegistro}</div>
                      <div style={styles.columnUser}>{mov.usuario.substring(0, 15)}...</div>
                    </div>
                  ))}
                </div>
              )}
            </div><br></br>
           {carregamento == false && <button onClick={()=>carregarTodas()} style={{
      backgroundColor: '#253221', 
      color: 'white', 
      padding: '15px 32px', 
      textAlign: 'center', 
      textDecoration: 'none', 
      display: 'inline-block', 
      fontSize: '18px', 
      margin: '4px 2px', 
      cursor: 'pointer', 
      border: 'none', 
      borderRadius: '4px',
      marginTop:30,
      marginBottom:30 
    }}>
      <b>CARREGAR REGISTROS ANTIGOS</b>
    </button>    } 
          </div>
        </div>
        {movimento.length > itemsPerPage && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Pagination>
      {paginationItems}
    </Pagination>
    
          </div>
        )}
      </center>
    </div>
  )
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    boxShadow: '0px 2px 2px #253221',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    maxWidth: '1400px',
    margin: 'auto',
    color: '#545f34',
    border: '1px solid black',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 0',
    borderBottom: '1px solid #ccc',
    fontSize: '16px',
  },
  column: {
    flex: '1',
    textAlign: 'center',
    color: '#545f34',
  },
  columnUser: {
    flex: '1',
    textAlign: 'center',
    color: '#545f34',
    fontSize:10
  },
  column3: {
    flex: '1',
    textAlign: 'center',
    color: '#545f34',
    fontSize: 12
  },
  columnC: {
    flex: '1',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'gray',
    fontSize: '11px',
  },
  columnCnome: {
    flex: '4',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'gray',
    fontSize: '11px',
  },
  nome: {
    flex: '4',
    textAlign: 'center',
    color: '#545f34',
    fontSize: '16px',
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  gray: {
    color: 'blue',
  },
};

export default Movimento;
