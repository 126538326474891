// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPCwq8513g_KkCqMhP1rfptc2OzmhvbLk",
  authDomain: "ppcdpv2.firebaseapp.com",
  projectId: "ppcdpv2",
  storageBucket: "ppcdpv2.appspot.com",
  messagingSenderId: "746902819097",
  appId: "1:746902819097:web:78b6896b171a89b375f588"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



