// import bootstrap - componentes
import axios from 'axios';
import { Button } from 'reactstrap';
import { Input } from 'reactstrap';
import { Form } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebase/config';
import { useDropzone } from 'react-dropzone';
import userDef from '../../assets/img/User-Default3.jpg'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Loading from '../../components/Loading';


const Cadastro = () => {
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imgURL, setImgURL] = useState("")
  const [startDate] = useState(new Date());
  const [nome, setNome] = useState("")
  const [cela, setCela] = useState("")
  const [origem, setOrigem] = useState("")
  const [infopen, setInfopen] = useState("")
  const [artigo, setArtigo] = useState("")
  const [selectValue, setSelectValue] = useState('A101');
  const [selectDate, setSelectDate] = useState('');
  const [usuarioLogado, setUsuarioLogado] = useState('');
  const [selecao, setSelecao] = useState("");
  const [visita, setVisita] = useState("");
  const [botaoClicado, setBotaoClicado] = useState(false);
  const [loading, setLoading] = useState(false)
  const [un, setUn] = useState('')



  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Usuário logado: ', user.email);
        setUsuarioLogado((user.email).toUpperCase());
      } else {
        console.log('Nenhum usuário logado.');
        setUsuarioLogado('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  console.log(usuarioLogado)

  const handleSelecao = (event) => {
    setSelecao(event.target.value);
  };
  const handleSelecaoVisita = (event) => {
    setVisita(event.target.value);
  };

  const handleDrop = (acceptedFiles) => {
    // Acessar o arquivo de imagem carregado
    const file = acceptedFiles[0];

    // Criar uma URL temporária para exibição da imagem
    const imageUrl = URL.createObjectURL(file);


    // Atualizar o estado com a imagem carregada
    setUploadedImage(imageUrl);

    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result1 = ' ';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }


    const storageRef = ref(storage, `images/${result1 + file.name}`)


    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      snapshot => {



      },

      error => {

        alert(error)
      },

      () => {

        getDownloadURL(uploadTask.snapshot.ref).then(url => {

          setImgURL(url)



        })
      }


    )
  };





  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: handleDrop
  });



  //listagem dos campos do select para cela
  const list = [

    // QUANDRANTE A101 A A112
    { id: 'A101', name: 'A101' },
    { id: 'A102', name: 'A102' },
    { id: 'A103', name: 'A103' },
    { id: 'A104', name: 'A104' },
    { id: 'A105', name: 'A105' },
    { id: 'A106', name: 'A106' },
    { id: 'A107', name: 'A107' },
    { id: 'A108', name: 'A108' },
    { id: 'A109', name: 'A109' },
    { id: 'A110', name: 'A110' },
    { id: 'A111', name: 'A111' },
    { id: 'A112', name: 'A112' },
    // QUANDRANTE A113 A A122
    { id: 'A113', name: 'A113' },
    { id: 'A114', name: 'A114' },
    { id: 'A115', name: 'A115' },
    { id: 'A116', name: 'A116' },
    { id: 'A117', name: 'A117' },
    { id: 'A118', name: 'A118' },
    { id: 'A119', name: 'A119' },
    { id: 'A120', name: 'A120' },
    { id: 'A121', name: 'A121' },
    { id: 'A122', name: 'A122' },


    // QUANDRANTE A201 A A212
    { id: 'A201', name: 'A201' },
    { id: 'A202', name: 'A202' },
    { id: 'A203', name: 'A203' },
    { id: 'A204', name: 'A204' },
    { id: 'A205', name: 'A205' },
    { id: 'A206', name: 'A206' },
    { id: 'A207', name: 'A207' },
    { id: 'A208', name: 'A208' },
    { id: 'A209', name: 'A209' },
    { id: 'A210', name: 'A210' },
    { id: 'A211', name: 'A211' },
    { id: 'A212', name: 'A212' },
    // QUANDRANTE A213 A A222
    { id: 'A213', name: 'A213' },
    { id: 'A214', name: 'A214' },
    { id: 'A215', name: 'A215' },
    { id: 'A216', name: 'A216' },
    { id: 'A217', name: 'A217' },
    { id: 'A218', name: 'A218' },
    { id: 'A219', name: 'A219' },
    { id: 'A220', name: 'A220' },
    { id: 'A221', name: 'A221' },
    { id: 'A222', name: 'A222' },




    // GALERIA CRAVO


    // QUANDRANTE A101 A A112
    { id: 'B101', name: 'B101' },
    { id: 'B102', name: 'B102' },
    { id: 'B103', name: 'B103' },
    { id: 'B104', name: 'B104' },
    { id: 'B105', name: 'B105' },
    { id: 'B106', name: 'B106' },
    { id: 'B107', name: 'B107' },
    { id: 'B108', name: 'B108' },
    { id: 'B109', name: 'B109' },
    { id: 'B110', name: 'B110' },
    { id: 'B111', name: 'B111' },
    { id: 'B112', name: 'B112' },
    { id: 'B113', name: 'B113' },
    { id: 'B114', name: 'B114' },
    { id: 'B115', name: 'B115' },
    { id: 'B116', name: 'B116' },
    { id: 'B117', name: 'B117' },
    { id: 'B118', name: 'B118' },
    { id: 'B119', name: 'B119' },
    { id: 'B120', name: 'B120' },
    { id: 'B121', name: 'B121' },
    { id: 'B122', name: 'B122' },

    { id: 'B201', name: 'B201' },
    { id: 'B202', name: 'B202' },
    { id: 'B203', name: 'B203' },
    { id: 'B204', name: 'B204' },
    { id: 'B205', name: 'B205' },
    { id: 'B206', name: 'B206' },
    { id: 'B207', name: 'B207' },
    { id: 'B208', name: 'B208' },
    { id: 'B209', name: 'B209' },
    { id: 'B210', name: 'B210' },
    { id: 'B211', name: 'B211' },
    { id: 'B212', name: 'B212' },
    { id: 'B213', name: 'B213' },
    { id: 'B214', name: 'B214' },
    { id: 'B215', name: 'B215' },
    { id: 'B216', name: 'B216' },
    { id: 'B217', name: 'B217' },
    { id: 'B218', name: 'B218' },
    { id: 'B219', name: 'B219' },
    { id: 'B220', name: 'B220' },
    { id: 'B221', name: 'B221' },
    { id: 'B222', name: 'B222' },

    // GALERIA CHARLIE 
    // QUANDRANTE A101 A A112
    { id: 'C101', name: 'C101' },
    { id: 'C102', name: 'C102' },
    { id: 'C103', name: 'C103' },
    { id: 'C104', name: 'C104' },
    { id: 'C105', name: 'C105' },
    { id: 'C106', name: 'C106' },
    { id: 'C107', name: 'C107' },
    { id: 'C108', name: 'C108' },
    { id: 'C109', name: 'C109' },
    { id: 'C110', name: 'C110' },
    { id: 'C111', name: 'C111' },
    { id: 'C112', name: 'C112' },
    { id: 'C113', name: 'C113' },
    { id: 'C114', name: 'C114' },

    { id: 'C201', name: 'C201' },
    { id: 'C202', name: 'C202' },
    { id: 'C203', name: 'C203' },
    { id: 'C204', name: 'C204' },
    { id: 'C205', name: 'C205' },
    { id: 'C206', name: 'C206' },
    { id: 'C207', name: 'C207' },
    { id: 'C208', name: 'C208' },
    { id: 'C209', name: 'C209' },
    { id: 'C210', name: 'C210' },
    { id: 'C211', name: 'C211' },
    { id: 'C212', name: 'C212' },
    { id: 'C213', name: 'C213' },
    { id: 'C214', name: 'C214' },


    // GALERIA DELTA
    { id: 'D101', name: 'D101' },
    { id: 'D102', name: 'D102' },
    { id: 'D103', name: 'D103' },
    { id: 'D104', name: 'D104' },
    { id: 'D105', name: 'D105' },
    { id: 'D106', name: 'D106' },
    { id: 'D107', name: 'D107' },
    { id: 'D108', name: 'D108' },
    { id: 'D109', name: 'D109' },
    { id: 'D110', name: 'D110' },
    { id: 'D111', name: 'D111' },
    { id: 'D112', name: 'D112' },
    { id: 'D113', name: 'D113' },
    { id: 'D114', name: 'D114' },

    { id: 'D201', name: 'D201' },
    { id: 'D202', name: 'D202' },
    { id: 'D203', name: 'D203' },
    { id: 'D204', name: 'D204' },
    { id: 'D205', name: 'D205' },
    { id: 'D206', name: 'D206' },
    { id: 'D207', name: 'D207' },
    { id: 'D208', name: 'D208' },
    { id: 'D209', name: 'D209' },
    { id: 'D210', name: 'D210' },
    { id: 'D211', name: 'D211' },
    { id: 'D212', name: 'D212' },
    { id: 'D213', name: 'D213' },
    { id: 'D214', name: 'D214' },

    { id: 'E101', name: 'E101' },
    { id: 'E102', name: 'E102' },
    { id: 'E103', name: 'E103' },
    { id: 'E104', name: 'E104' },
    { id: 'E105', name: 'E105' },
    { id: 'E106', name: 'E106' },
    { id: 'E107', name: 'E107' },
    { id: 'E108', name: 'E108' },
    { id: 'E109', name: 'E109' },
    { id: 'E110', name: 'E110' },
    { id: 'E111', name: 'E111' },
    { id: 'E112', name: 'E112' },
    { id: 'E113', name: 'E113' },
    { id: 'E114', name: 'E114' },
    { id: 'E115', name: 'E115' },
    { id: 'E116', name: 'E116' },
    { id: 'E117', name: 'E117' },
    { id: 'E118', name: 'E118' },
    { id: 'E119', name: 'E119' },
    { id: 'E120', name: 'E120' },
    { id: 'E121', name: 'E121' },
    { id: 'E122', name: 'E122' },

    { id: 'E201', name: 'E201' },
    { id: 'E202', name: 'E202' },
    { id: 'E203', name: 'E203' },
    { id: 'E204', name: 'E204' },
    { id: 'E205', name: 'E205' },
    { id: 'E206', name: 'E206' },
    { id: 'E207', name: 'E207' },
    { id: 'E208', name: 'E208' },
    { id: 'E209', name: 'E209' },
    { id: 'E210', name: 'E210' },
    { id: 'E211', name: 'E211' },
    { id: 'E212', name: 'E212' },
    { id: 'E213', name: 'E213' },
    { id: 'E214', name: 'E214' },
    { id: 'E215', name: 'E215' },
    { id: 'E216', name: 'E216' },
    { id: 'E217', name: 'E217' },
    { id: 'E218', name: 'E218' },
    { id: 'E219', name: 'E219' },
    { id: 'E220', name: 'E220' },
    { id: 'E221', name: 'E221' },
    { id: 'E222', name: 'E222' },

    { id: 'F101', name: 'F101' },
    { id: 'F102', name: 'F102' },
    { id: 'F103', name: 'F103' },
    { id: 'F104', name: 'F104' },
    { id: 'F105', name: 'F105' },
    { id: 'F106', name: 'F106' },
    { id: 'F107', name: 'F107' },
    { id: 'F108', name: 'F108' },
    { id: 'F109', name: 'F109' },
    { id: 'F110', name: 'F110' },
    { id: 'F111', name: 'F111' },
    { id: 'F112', name: 'F112' },
    { id: 'F113', name: 'F113' },
    { id: 'F114', name: 'F114' },

    { id: 'F201', name: 'F201' },
    { id: 'F202', name: 'F202' },
    { id: 'F203', name: 'F203' },
    { id: 'F204', name: 'F204' },
    { id: 'F205', name: 'F205' },
    { id: 'F206', name: 'F206' },
    { id: 'F207', name: 'F207' },
    { id: 'F208', name: 'F208' },
    { id: 'F209', name: 'F209' },
    { id: 'F210', name: 'F210' },
    { id: 'F211', name: 'F211' },
    { id: 'F212', name: 'F212' },
    { id: 'F213', name: 'F213' },
    { id: 'F214', name: 'F214' },
    //triagem 
    { id: 'G201', name: 'G201' },
    { id: 'G202', name: 'G202' },
    { id: 'G203', name: 'G203' },
    { id: 'G204', name: 'G204' },
    { id: 'G205', name: 'G205' },
    { id: 'G206', name: 'G206' },
    { id: 'G207', name: 'G207' },
    { id: 'G208', name: 'G208' },
    { id: 'G209', name: 'G209' },
    { id: 'G210', name: 'G210' },
    //fora da unidade 
    { id: 'TR201', name: 'TR201' },
    { id: 'TR202', name: 'TR202' },
  ];
  //fim da listagem de select




  useEffect(() => {
    setCela(selectValue)


  }, [selectValue])



  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setBotaoClicado(true);

    const dataAtual = new Date();
    const diaAtual = dataAtual.getDate();
    const mesAtual = dataAtual.getMonth() + 1; // Janeiro é 0
    const anoAtual = dataAtual.getFullYear();
    const horaAtual = dataAtual.getHours();
    const minutoAtual = dataAtual.getMinutes();
    const dataHoraFormatada = `${String(diaAtual).padStart(2, '0')}/${mesAtual}/${anoAtual} ${horaAtual}:${minutoAtual}`;
    var dataSobe = selectDate + ` ${String(horaAtual).padStart(2, '0')}:${String(minutoAtual).padStart(2, '0')}`
    console.log('data e hora ---------' + dataHoraFormatada)
    const dados = {
      nome: nome,
      cela: cela,
      dataEntrada: selectDate,
      infopen: infopen,
      origem: origem,
      dataRegistro: dataSobe,
      usuario: usuarioLogado,
      tipo: 'ENTRADA',

    };

    console.log('data formatada2' + dataHoraFormatada)
    axios.post('https://alertadiarioes.com/apicdpv2/post_movimento.php', dados)
      .then((response) => {
        console.log('Resposta da API:', response.data);
        // Aqui você pode lidar com a resposta da API, se necessário
      })
      .catch((error) => {
        console.error('Erro ao enviar dados para a API:', error);
      });

    const data = {

      nome,
      cela,
      origem,
      infopen,
      selectDate,
      artigo,
      imgURL,
      selecao,
      visita,
    };

    async function enviarDadosParaAPI(data) {
      const url = 'https://alertadiarioes.com/apicdpv2/insert_id_galerias_cdpg.php';

      try {
        // Envia uma requisição POST para a API com os dados
        const response = await axios.post(url, data);

        // Exibe a resposta da API no console
        console.log('Resposta da API:', response.data);
      } catch (error) {
        // Exibe os erros no console, se houver algum
        console.error('Erro ao enviar dados para a API:', error);
      }
    }
    console.log('data funcao ----->' + data)
    // Chama a função para enviar dados para a API
    await enviarDadosParaAPI(data);



    setArtigo('')
    setNome('')
    setInfopen('')
    setArtigo('')
    setImgURL('')
    setOrigem('')
    setUploadedImage('')
    setVisita('')
    setSelecao('')
    window.scrollTo(0, 0);

    setBotaoClicado(false);
    navigate('/')
    window.location.reload()



  }



  useEffect(() => {
    if (startDate) {
      const dia = startDate.getDate().toString().padStart(2, '0');
      const mes = (startDate.getMonth() + 1).toString().padStart(2, '0');
      const ano = startDate.getFullYear();
      setSelectDate(`${dia}/${mes}/${ano}`);
    }
  }, [startDate]);


  return (<>


    <center>
      <div style={{ color: 'black', borderRadius: 10, marginTop: '40px', backgroundColor: 'none', width: '70%', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginBottom: 60 }}>
        <div style={{ marginTop: 60, fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, }}><h1>REGISTRO DE ENTRADAS</h1><h5>CADASTRO DE NOVOS PRESOS NA CONTAGEM</h5> </p><br></br> </center></div>
        {loading && <Loading></Loading>}
        {!loading && <Form onSubmit={handleSubmit}>

          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {uploadedImage ? (
              <center><img src={uploadedImage} alt='PPES' height={250} /><center><p style={{ fontSize: 18, color: '#8a9175' }}>Imagem carregada com sucesso.</p></center></center>
            ) : (
              <div><center> <br></br><img alt='PPES' style={{ border: '1px solid #253221', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} src={userDef}></img></center><center><br></br><p style={{ fontSize: 18, color: '#253221' }}>Arraste e solte uma imagem no quadro ou clique para selecionar o arquivo no seu computador.</p></center></div>
            )}
          </div><br></br>

          <FormGroup>

            <Label for="exampleEmail">
              <p style={{ color: '#8a9175', marginBottom: -3, }}><b>NOME*</b></p>
            </Label>
            <Input
              id="nome"
              name="nome"
              placeholder="Nome completo"
              type="text"
              required
              value={nome}
              style={{ border: '2px solid #253221' }}
              onChange={(e) => { setNome(e.target.value.toUpperCase()) }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="examplePassword">
              <p style={{ color: '#8a9175', marginBottom: -3 }}><b>ORIGEM*</b></p>
            </Label>
            <Input
              id="origem"
              name="origem"
              placeholder="Origem do recebimento"
              type="text"
              required
              value={origem}
              style={{ border: '2px solid #253221' }}
              onChange={(e) => { setOrigem(e.target.value.toUpperCase()) }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelect">
              <p style={{ color: '#8a9175', marginBottom: -3 }}><b>CELA DE DESTINO*</b></p>
            </Label>
            <Input
              id="exampleSelect"
              name="select"
              style={{ border: '2px solid #253221' }}
              type="select"
              required
              value={selectValue} onChange={e => setSelectValue(e.target.value)}
            >
              {list.map((item, index) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup >
            <Label for="exampleSelectMulti">
              <p style={{ color: '#8a9175', marginBottom: -3 }}><b>DATA DA ENTRADA*</b></p>
            </Label>

            <Input
              id="origem"
              name="origem"
              placeholder="Origem do recebimento"

              required
              value={selectDate}
              style={{ border: '2px solid #253221' }}
              onChange={(e) => setSelectDate(e.target.value)}
            />




          </FormGroup>
          <br></br>
          <center><small><b style={{ color: '#8a9175' }}>INFORMAÇÕES COMPLEMENTARES</b></small></center><br></br>
          <FormGroup>
            <Label for="exampleFile">

            </Label>
        
            <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}><b>INFOPEN</b></p>
              </Label>
              <Input
                id="infopen"
                name="infopen"
                placeholder="Número do INFOPEN"
                type="number"
                style={{ border: '2px solid #253221' }}
                value={infopen}
                onChange={(e) => { setInfopen(e.target.value.toUpperCase()) }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}> <b>ARTIGO</b></p>
              </Label>
              <Input
                id="artigo"
                name="artigo"
                placeholder="Artigo"
                type="text"
                value={artigo}
                style={{ border: '2px solid #253221' }}
                onChange={(e) => { setArtigo(e.target.value.toUpperCase()) }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}> <b>TIPO DE CRIME</b></p>
              </Label>
              <Input style={{ border: '2px solid #253221' }} type="select" name="tipoCrime" id="tipoCrimeSelect" value={selecao} onChange={handleSelecao}>
                <option value="">Selecione</option>
                <option value="PRIM.">PRIMÁRIO</option>
                <option value="REIN.">REINCIDENTE</option>
              </Input>
            </FormGroup>


          </FormGroup>
          <br></br>
          <center><Button disabled={botaoClicado}
            style={{ backgroundColor: '#8a9175', color: 'white', fontFamily: 'policiapenal', fontSize: 14, padding: 14, marginBottom: 30 }}>CADASTRAR</Button></center>
        </Form>}</div></center></>
  )
}

export default Cadastro