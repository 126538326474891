import { useEffect, useState } from 'react';
import styles from './Contagem.module.css'
import Celacontagem from '../../components/Celacontagem'
import { Button, Table } from 'reactstrap';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FiPrinter } from "react-icons/fi";
import logo from '../../assets/img/logo.png';
import excel from '../../assets/img/excel.png';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";


const Contagem = ({galerias}) => {
 
  const [pagina, setPagina] = useState(6)
  const [ativaBotao, setAtivaBotao] = useState(6)
  const data = new Date();
  const dia = String(data.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se for necessário
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero, então adicionamos 1
  const ano = data.getFullYear();
  const agora = new Date();
  const hora = agora.getHours();
  const minutos = agora.getMinutes();
  const minutosFormatados = minutos.toString().padStart(2, '0');
  const [pessoasDomiciliar, setPessoasDomiciliar] = useState('')
  const [pessoasHospital, setPessoasHospital] = useState('')
  const [pessoasPernoite, setPessoasPernoite] = useState('')


  
  

   


  const exportToExcel = () => {
    // Função para extrair apenas os números da cela
    const extrairNumerosCela = (cela) => {
      if (!cela) return ''; // Se a cela for vazia, retorna uma string vazia
      return cela.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    };
  
    // Função para determinar a galeria com base na cela
    const getGaleria = (cela) => {
      // Verifica se a cela não está vazia
      if (cela && cela.trim() !== '') {
        const duasPrimeirasLetras = cela.substring(0, 2).toUpperCase();
        switch (duasPrimeirasLetras) {
          case 'DO':
            return 'P._DOMICILIAR';
          case 'A1':
            return 'ALFA';
          case 'A2':
            return 'ALFA';
          case 'B1':
            return 'BRAVO';
          case 'B2':
            return 'BRAVO';
          case 'C1':
            return 'CHARLIE';
          case 'C2':
            return 'CHARLIE';
          case 'D1':
            return 'DELTA';
          case 'D2':
            return 'DELTA';
          case 'E1':
            return 'ECHO';
          case 'E2':
            return 'ECHO';
          case 'F1':
            return 'FOX';
          case 'F2':
            return 'FOX';
          case 'G1':
            return 'GOLF';
          case 'G2':
            return 'GOLF';
          case 'TR':
            return 'TRIAGEM';
          case 'HO':
            return 'INT._HOSPITALAR';
          case 'PE':
            return 'PERNOITE';
          default:
            return ''; // Retorna uma string vazia se não corresponder a nenhum caso específico
        }
      } else {
        return ''; // Retorna uma string vazia se a cela estiver vazia
      }
    };
  
    // Filtra as galerias com cela não vazia e correspondente aos casos específicos
    const galeriasFiltradas = galerias.filter(item => {
      if (!item.cela || item.cela.trim() === '') return false; // Se a cela estiver vazia, não exporta
      const duasPrimeirasLetras = item.cela.substring(0, 2).toUpperCase();
      return ['A1','A2', 'B1','B2', 'C1','C2', 'D1', 'D2', 'E1','E2', 'F1','F2','G1','G2', 'TR', 'HO', 'PE', 'DO'].includes(duasPrimeirasLetras); // Exporta apenas se as duas primeiras letras da cela corresponderem a essas letras
    });
  
    // Ordena os dados pelo campo "cela"
    const sortedData = galeriasFiltradas
      .sort((a, b) => (a.cela < b.cela ? -1 : 1))
      .map((item) => {
        // Extrai apenas os números da cela
        const celaNumerica = extrairNumerosCela(item.cela);
        // Determina a galeria com base na cela
        const galeria = getGaleria(item.cela);
        return { galeria, ...item };
      });
  
    // Define a ordem das colunas no Excel
    // Função para remover todas as letras de uma string
const removerLetras = (str) => {
  if (!str) return ''; // Retorna uma string vazia se o parâmetro for nulo ou vazio
  return str.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
};

// Define a ordem das colunas no Excel
const columnsOrder = ["infopen", "galeria", "cela", "nome", "origem", "selectDate" ,"entradaGaleria", "artigo", "selecao"];

// Mapeia os dados para garantir a ordem das colunas e remover letras da cela
const orderedData = sortedData.map(item => {
  const orderedItem = {};
  columnsOrder.forEach(column => {
    // Se a coluna for "cela", remove todas as letras antes de atribuir ao novo objeto
    if (column === "cela") {
      orderedItem[column] = removerLetras(item[column]);
    } else {
      orderedItem[column] = item[column];
    }
  });
  return orderedItem;
});

  
    // Converte os dados para uma planilha Excel
    const ws = XLSX.utils.json_to_sheet(orderedData);
  
    // Cria o workbook
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
    // Escreve o arquivo Excel
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
    // Cria um Blob para o arquivo Excel
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
    // Salva o arquivo Excel
    FileSaver.saveAs(dataBlob, `Contagem.xlsx`);
  };
  
  

  const imprimirConteudo = () => {
    const conteudoParaImpressao = document.querySelector('.conteudo-para-impressao');
    if (conteudoParaImpressao) {
      window.print(conteudoParaImpressao);
    }
  };


  // criação das variáveis MAP GALERIA A
 
  // criação das variáveis MAP GALERIA A
  const [pessoasSearchMapA101, setPessoasSearchMapA101] = useState('')
  const [pessoasSearchMapA102, setPessoasSearchMapA102] = useState('')
  const [pessoasSearchMapA103, setPessoasSearchMapA103] = useState('')
  const [pessoasSearchMapA104, setPessoasSearchMapA104] = useState('')
  const [pessoasSearchMapA105, setPessoasSearchMapA105] = useState('')
  const [pessoasSearchMapA106, setPessoasSearchMapA106] = useState('')
  const [pessoasSearchMapA107, setPessoasSearchMapA107] = useState('')
  const [pessoasSearchMapA108, setPessoasSearchMapA108] = useState('')
  const [pessoasSearchMapA109, setPessoasSearchMapA109] = useState('')
  const [pessoasSearchMapA110, setPessoasSearchMapA110] = useState('')
  const [pessoasSearchMapA111, setPessoasSearchMapA111] = useState('')
  const [pessoasSearchMapA112, setPessoasSearchMapA112] = useState('')
  const [pessoasSearchMapA113, setPessoasSearchMapA113] = useState('')
  const [pessoasSearchMapA114, setPessoasSearchMapA114] = useState('')
  const [pessoasSearchMapA115, setPessoasSearchMapA115] = useState('')
  const [pessoasSearchMapA116, setPessoasSearchMapA116] = useState('')
  const [pessoasSearchMapA117, setPessoasSearchMapA117] = useState('')
  const [pessoasSearchMapA118, setPessoasSearchMapA118] = useState('')
  const [pessoasSearchMapA119, setPessoasSearchMapA119] = useState('')
  const [pessoasSearchMapA120, setPessoasSearchMapA120] = useState('')
  const [pessoasSearchMapA121, setPessoasSearchMapA121] = useState('')
  const [pessoasSearchMapA122, setPessoasSearchMapA122] = useState('')
  const [pessoasSearchMapA201, setPessoasSearchMapA201] = useState('')
  const [pessoasSearchMapA202, setPessoasSearchMapA202] = useState('')
  const [pessoasSearchMapA203, setPessoasSearchMapA203] = useState('')
  const [pessoasSearchMapA204, setPessoasSearchMapA204] = useState('')
  const [pessoasSearchMapA205, setPessoasSearchMapA205] = useState('')
  const [pessoasSearchMapA206, setPessoasSearchMapA206] = useState('')
  const [pessoasSearchMapA207, setPessoasSearchMapA207] = useState('')
  const [pessoasSearchMapA208, setPessoasSearchMapA208] = useState('')
  const [pessoasSearchMapA209, setPessoasSearchMapA209] = useState('')
  const [pessoasSearchMapA210, setPessoasSearchMapA210] = useState('')
  const [pessoasSearchMapA211, setPessoasSearchMapA211] = useState('')
  const [pessoasSearchMapA212, setPessoasSearchMapA212] = useState('')
  const [pessoasSearchMapA213, setPessoasSearchMapA213] = useState('')
  const [pessoasSearchMapA214, setPessoasSearchMapA214] = useState('')
  const [pessoasSearchMapA215, setPessoasSearchMapA215] = useState('')
  const [pessoasSearchMapA216, setPessoasSearchMapA216] = useState('')
  const [pessoasSearchMapA217, setPessoasSearchMapA217] = useState('')
  const [pessoasSearchMapA218, setPessoasSearchMapA218] = useState('')
  const [pessoasSearchMapA219, setPessoasSearchMapA219] = useState('')
  const [pessoasSearchMapA220, setPessoasSearchMapA220] = useState('')
  const [pessoasSearchMapA221, setPessoasSearchMapA221] = useState('')
  const [pessoasSearchMapA222, setPessoasSearchMapA222] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB101, setPessoasSearchMapB101] = useState('')
  const [pessoasSearchMapB102, setPessoasSearchMapB102] = useState('')
  const [pessoasSearchMapB103, setPessoasSearchMapB103] = useState('')
  const [pessoasSearchMapB104, setPessoasSearchMapB104] = useState('')
  const [pessoasSearchMapB105, setPessoasSearchMapB105] = useState('')
  const [pessoasSearchMapB106, setPessoasSearchMapB106] = useState('')
  const [pessoasSearchMapB107, setPessoasSearchMapB107] = useState('')
  const [pessoasSearchMapB108, setPessoasSearchMapB108] = useState('')
  const [pessoasSearchMapB109, setPessoasSearchMapB109] = useState('')
  const [pessoasSearchMapB110, setPessoasSearchMapB110] = useState('')
  const [pessoasSearchMapB111, setPessoasSearchMapB111] = useState('')
  const [pessoasSearchMapB112, setPessoasSearchMapB112] = useState('')
  const [pessoasSearchMapB113, setPessoasSearchMapB113] = useState('')
  const [pessoasSearchMapB114, setPessoasSearchMapB114] = useState('')
  const [pessoasSearchMapB115, setPessoasSearchMapB115] = useState('')
  const [pessoasSearchMapB116, setPessoasSearchMapB116] = useState('')
  const [pessoasSearchMapB117, setPessoasSearchMapB117] = useState('')
  const [pessoasSearchMapB118, setPessoasSearchMapB118] = useState('')
  const [pessoasSearchMapB119, setPessoasSearchMapB119] = useState('')
  const [pessoasSearchMapB120, setPessoasSearchMapB120] = useState('')
  const [pessoasSearchMapB121, setPessoasSearchMapB121] = useState('')
  const [pessoasSearchMapB122, setPessoasSearchMapB122] = useState('')
  const [pessoasSearchMapB201, setPessoasSearchMapB201] = useState('')
  const [pessoasSearchMapB202, setPessoasSearchMapB202] = useState('')
  const [pessoasSearchMapB203, setPessoasSearchMapB203] = useState('')
  const [pessoasSearchMapB204, setPessoasSearchMapB204] = useState('')
  const [pessoasSearchMapB205, setPessoasSearchMapB205] = useState('')
  const [pessoasSearchMapB206, setPessoasSearchMapB206] = useState('')
  const [pessoasSearchMapB207, setPessoasSearchMapB207] = useState('')
  const [pessoasSearchMapB208, setPessoasSearchMapB208] = useState('')
  const [pessoasSearchMapB209, setPessoasSearchMapB209] = useState('')
  const [pessoasSearchMapB210, setPessoasSearchMapB210] = useState('')
  const [pessoasSearchMapB211, setPessoasSearchMapB211] = useState('')
  const [pessoasSearchMapB212, setPessoasSearchMapB212] = useState('')
  const [pessoasSearchMapB213, setPessoasSearchMapB213] = useState('')
  const [pessoasSearchMapB214, setPessoasSearchMapB214] = useState('')
  const [pessoasSearchMapB215, setPessoasSearchMapB215] = useState('')
  const [pessoasSearchMapB216, setPessoasSearchMapB216] = useState('')
  const [pessoasSearchMapB217, setPessoasSearchMapB217] = useState('')
  const [pessoasSearchMapB218, setPessoasSearchMapB218] = useState('')
  const [pessoasSearchMapB219, setPessoasSearchMapB219] = useState('')
  const [pessoasSearchMapB220, setPessoasSearchMapB220] = useState('')
  const [pessoasSearchMapB221, setPessoasSearchMapB221] = useState('')
  const [pessoasSearchMapB222, setPessoasSearchMapB222] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC101, setPessoasSearchMapC101] = useState('')
  const [pessoasSearchMapC102, setPessoasSearchMapC102] = useState('')
  const [pessoasSearchMapC103, setPessoasSearchMapC103] = useState('')
  const [pessoasSearchMapC104, setPessoasSearchMapC104] = useState('')
  const [pessoasSearchMapC105, setPessoasSearchMapC105] = useState('')
  const [pessoasSearchMapC106, setPessoasSearchMapC106] = useState('')
  const [pessoasSearchMapC107, setPessoasSearchMapC107] = useState('')
  const [pessoasSearchMapC108, setPessoasSearchMapC108] = useState('')
  const [pessoasSearchMapC109, setPessoasSearchMapC109] = useState('')
  const [pessoasSearchMapC110, setPessoasSearchMapC110] = useState('')
  const [pessoasSearchMapC111, setPessoasSearchMapC111] = useState('')
  const [pessoasSearchMapC112, setPessoasSearchMapC112] = useState('')
  const [pessoasSearchMapC113, setPessoasSearchMapC113] = useState('')
  const [pessoasSearchMapC114, setPessoasSearchMapC114] = useState('')
  const [pessoasSearchMapC115, setPessoasSearchMapC115] = useState('')
  const [pessoasSearchMapC116, setPessoasSearchMapC116] = useState('')
  const [pessoasSearchMapC117, setPessoasSearchMapC117] = useState('')
  const [pessoasSearchMapC118, setPessoasSearchMapC118] = useState('')
  const [pessoasSearchMapC119, setPessoasSearchMapC119] = useState('')
  const [pessoasSearchMapC120, setPessoasSearchMapC120] = useState('')
  const [pessoasSearchMapC121, setPessoasSearchMapC121] = useState('')
  const [pessoasSearchMapC122, setPessoasSearchMapC122] = useState('')
  const [pessoasSearchMapC201, setPessoasSearchMapC201] = useState('')
  const [pessoasSearchMapC202, setPessoasSearchMapC202] = useState('')
  const [pessoasSearchMapC203, setPessoasSearchMapC203] = useState('')
  const [pessoasSearchMapC204, setPessoasSearchMapC204] = useState('')
  const [pessoasSearchMapC205, setPessoasSearchMapC205] = useState('')
  const [pessoasSearchMapC206, setPessoasSearchMapC206] = useState('')
  const [pessoasSearchMapC207, setPessoasSearchMapC207] = useState('')
  const [pessoasSearchMapC208, setPessoasSearchMapC208] = useState('')
  const [pessoasSearchMapC209, setPessoasSearchMapC209] = useState('')
  const [pessoasSearchMapC210, setPessoasSearchMapC210] = useState('')
  const [pessoasSearchMapC211, setPessoasSearchMapC211] = useState('')
  const [pessoasSearchMapC212, setPessoasSearchMapC212] = useState('')
  const [pessoasSearchMapC213, setPessoasSearchMapC213] = useState('')
  const [pessoasSearchMapC214, setPessoasSearchMapC214] = useState('')
  const [pessoasSearchMapC215, setPessoasSearchMapC215] = useState('')
  const [pessoasSearchMapC216, setPessoasSearchMapC216] = useState('')
  const [pessoasSearchMapC217, setPessoasSearchMapC217] = useState('')
  const [pessoasSearchMapC218, setPessoasSearchMapC218] = useState('')
  const [pessoasSearchMapC219, setPessoasSearchMapC219] = useState('')
  const [pessoasSearchMapC220, setPessoasSearchMapC220] = useState('')
  const [pessoasSearchMapC221, setPessoasSearchMapC221] = useState('')
  const [pessoasSearchMapC222, setPessoasSearchMapC222] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapD101, setPessoasSearchMapD101] = useState('')
  const [pessoasSearchMapD102, setPessoasSearchMapD102] = useState('')
  const [pessoasSearchMapD103, setPessoasSearchMapD103] = useState('')
  const [pessoasSearchMapD104, setPessoasSearchMapD104] = useState('')
  const [pessoasSearchMapD105, setPessoasSearchMapD105] = useState('')
  const [pessoasSearchMapD106, setPessoasSearchMapD106] = useState('')
  const [pessoasSearchMapD107, setPessoasSearchMapD107] = useState('')
  const [pessoasSearchMapD108, setPessoasSearchMapD108] = useState('')
  const [pessoasSearchMapD109, setPessoasSearchMapD109] = useState('')
  const [pessoasSearchMapD110, setPessoasSearchMapD110] = useState('')
  const [pessoasSearchMapD111, setPessoasSearchMapD111] = useState('')
  const [pessoasSearchMapD112, setPessoasSearchMapD112] = useState('')
  const [pessoasSearchMapD113, setPessoasSearchMapD113] = useState('')
  const [pessoasSearchMapD114, setPessoasSearchMapD114] = useState('')
  const [pessoasSearchMapD115, setPessoasSearchMapD115] = useState('')
  const [pessoasSearchMapD116, setPessoasSearchMapD116] = useState('')
  const [pessoasSearchMapD117, setPessoasSearchMapD117] = useState('')
  const [pessoasSearchMapD118, setPessoasSearchMapD118] = useState('')
  const [pessoasSearchMapD119, setPessoasSearchMapD119] = useState('')
  const [pessoasSearchMapD120, setPessoasSearchMapD120] = useState('')
  const [pessoasSearchMapD121, setPessoasSearchMapD121] = useState('')
  const [pessoasSearchMapD122, setPessoasSearchMapD122] = useState('')
  const [pessoasSearchMapD201, setPessoasSearchMapD201] = useState('')
  const [pessoasSearchMapD202, setPessoasSearchMapD202] = useState('')
  const [pessoasSearchMapD203, setPessoasSearchMapD203] = useState('')
  const [pessoasSearchMapD204, setPessoasSearchMapD204] = useState('')
  const [pessoasSearchMapD205, setPessoasSearchMapD205] = useState('')
  const [pessoasSearchMapD206, setPessoasSearchMapD206] = useState('')
  const [pessoasSearchMapD207, setPessoasSearchMapD207] = useState('')
  const [pessoasSearchMapD208, setPessoasSearchMapD208] = useState('')
  const [pessoasSearchMapD209, setPessoasSearchMapD209] = useState('')
  const [pessoasSearchMapD210, setPessoasSearchMapD210] = useState('')
  const [pessoasSearchMapD211, setPessoasSearchMapD211] = useState('')
  const [pessoasSearchMapD212, setPessoasSearchMapD212] = useState('')
  const [pessoasSearchMapD213, setPessoasSearchMapD213] = useState('')
  const [pessoasSearchMapD214, setPessoasSearchMapD214] = useState('')
  const [pessoasSearchMapD215, setPessoasSearchMapD215] = useState('')
  const [pessoasSearchMapD216, setPessoasSearchMapD216] = useState('')
  const [pessoasSearchMapD217, setPessoasSearchMapD217] = useState('')
  const [pessoasSearchMapD218, setPessoasSearchMapD218] = useState('')
  const [pessoasSearchMapD219, setPessoasSearchMapD219] = useState('')
  const [pessoasSearchMapD220, setPessoasSearchMapD220] = useState('')
  const [pessoasSearchMapD221, setPessoasSearchMapD221] = useState('')
  const [pessoasSearchMapD222, setPessoasSearchMapD222] = useState('')


  // GALERIA E

  const [pessoasSearchMapE101, setPessoasSearchMapE101] = useState('')
  const [pessoasSearchMapE102, setPessoasSearchMapE102] = useState('')
  const [pessoasSearchMapE103, setPessoasSearchMapE103] = useState('')
  const [pessoasSearchMapE104, setPessoasSearchMapE104] = useState('')
  const [pessoasSearchMapE105, setPessoasSearchMapE105] = useState('')
  const [pessoasSearchMapE106, setPessoasSearchMapE106] = useState('')
  const [pessoasSearchMapE107, setPessoasSearchMapE107] = useState('')
  const [pessoasSearchMapE108, setPessoasSearchMapE108] = useState('')
  const [pessoasSearchMapE109, setPessoasSearchMapE109] = useState('')
  const [pessoasSearchMapE110, setPessoasSearchMapE110] = useState('')
  const [pessoasSearchMapE111, setPessoasSearchMapE111] = useState('')
  const [pessoasSearchMapE112, setPessoasSearchMapE112] = useState('')
  const [pessoasSearchMapE113, setPessoasSearchMapE113] = useState('')
  const [pessoasSearchMapE114, setPessoasSearchMapE114] = useState('')
  const [pessoasSearchMapE115, setPessoasSearchMapE115] = useState('')
  const [pessoasSearchMapE116, setPessoasSearchMapE116] = useState('')
  const [pessoasSearchMapE117, setPessoasSearchMapE117] = useState('')
  const [pessoasSearchMapE118, setPessoasSearchMapE118] = useState('')
  const [pessoasSearchMapE119, setPessoasSearchMapE119] = useState('')
  const [pessoasSearchMapE120, setPessoasSearchMapE120] = useState('')
  const [pessoasSearchMapE121, setPessoasSearchMapE121] = useState('')
  const [pessoasSearchMapE122, setPessoasSearchMapE122] = useState('')
  const [pessoasSearchMapE201, setPessoasSearchMapE201] = useState('')
  const [pessoasSearchMapE202, setPessoasSearchMapE202] = useState('')
  const [pessoasSearchMapE203, setPessoasSearchMapE203] = useState('')
  const [pessoasSearchMapE204, setPessoasSearchMapE204] = useState('')
  const [pessoasSearchMapE205, setPessoasSearchMapE205] = useState('')
  const [pessoasSearchMapE206, setPessoasSearchMapE206] = useState('')
  const [pessoasSearchMapE207, setPessoasSearchMapE207] = useState('')
  const [pessoasSearchMapE208, setPessoasSearchMapE208] = useState('')
  const [pessoasSearchMapE209, setPessoasSearchMapE209] = useState('')
  const [pessoasSearchMapE210, setPessoasSearchMapE210] = useState('')
  const [pessoasSearchMapE211, setPessoasSearchMapE211] = useState('')
  const [pessoasSearchMapE212, setPessoasSearchMapE212] = useState('')
  const [pessoasSearchMapE213, setPessoasSearchMapE213] = useState('')
  const [pessoasSearchMapE214, setPessoasSearchMapE214] = useState('')
  const [pessoasSearchMapE215, setPessoasSearchMapE215] = useState('')
  const [pessoasSearchMapE216, setPessoasSearchMapE216] = useState('')
  const [pessoasSearchMapE217, setPessoasSearchMapE217] = useState('')
  const [pessoasSearchMapE218, setPessoasSearchMapE218] = useState('')
  const [pessoasSearchMapE219, setPessoasSearchMapE219] = useState('')
  const [pessoasSearchMapE220, setPessoasSearchMapE220] = useState('')
  const [pessoasSearchMapE221, setPessoasSearchMapE221] = useState('')
  const [pessoasSearchMapE222, setPessoasSearchMapE222] = useState('')

  // GALERIA F

  const [pessoasSearchMapF101, setPessoasSearchMapF101] = useState('')
  const [pessoasSearchMapF102, setPessoasSearchMapF102] = useState('')
  const [pessoasSearchMapF103, setPessoasSearchMapF103] = useState('')
  const [pessoasSearchMapF104, setPessoasSearchMapF104] = useState('')
  const [pessoasSearchMapF105, setPessoasSearchMapF105] = useState('')
  const [pessoasSearchMapF106, setPessoasSearchMapF106] = useState('')
  const [pessoasSearchMapF107, setPessoasSearchMapF107] = useState('')
  const [pessoasSearchMapF108, setPessoasSearchMapF108] = useState('')
  const [pessoasSearchMapF109, setPessoasSearchMapF109] = useState('')
  const [pessoasSearchMapF110, setPessoasSearchMapF110] = useState('')
  const [pessoasSearchMapF111, setPessoasSearchMapF111] = useState('')
  const [pessoasSearchMapF112, setPessoasSearchMapF112] = useState('')
  const [pessoasSearchMapF113, setPessoasSearchMapF113] = useState('')
  const [pessoasSearchMapF114, setPessoasSearchMapF114] = useState('')
  const [pessoasSearchMapF115, setPessoasSearchMapF115] = useState('')
  const [pessoasSearchMapF116, setPessoasSearchMapF116] = useState('')
  const [pessoasSearchMapF117, setPessoasSearchMapF117] = useState('')
  const [pessoasSearchMapF118, setPessoasSearchMapF118] = useState('')
  const [pessoasSearchMapF119, setPessoasSearchMapF119] = useState('')
  const [pessoasSearchMapF120, setPessoasSearchMapF120] = useState('')
  const [pessoasSearchMapF121, setPessoasSearchMapF121] = useState('')
  const [pessoasSearchMapF122, setPessoasSearchMapF122] = useState('')
  const [pessoasSearchMapF201, setPessoasSearchMapF201] = useState('')
  const [pessoasSearchMapF202, setPessoasSearchMapF202] = useState('')
  const [pessoasSearchMapF203, setPessoasSearchMapF203] = useState('')
  const [pessoasSearchMapF204, setPessoasSearchMapF204] = useState('')
  const [pessoasSearchMapF205, setPessoasSearchMapF205] = useState('')
  const [pessoasSearchMapF206, setPessoasSearchMapF206] = useState('')
  const [pessoasSearchMapF207, setPessoasSearchMapF207] = useState('')
  const [pessoasSearchMapF208, setPessoasSearchMapF208] = useState('')
  const [pessoasSearchMapF209, setPessoasSearchMapF209] = useState('')
  const [pessoasSearchMapF210, setPessoasSearchMapF210] = useState('')
  const [pessoasSearchMapF211, setPessoasSearchMapF211] = useState('')
  const [pessoasSearchMapF212, setPessoasSearchMapF212] = useState('')
  const [pessoasSearchMapF213, setPessoasSearchMapF213] = useState('')
  const [pessoasSearchMapF214, setPessoasSearchMapF214] = useState('')
  const [pessoasSearchMapF215, setPessoasSearchMapF215] = useState('')
  const [pessoasSearchMapF216, setPessoasSearchMapF216] = useState('')
  const [pessoasSearchMapF217, setPessoasSearchMapF217] = useState('')
  const [pessoasSearchMapF218, setPessoasSearchMapF218] = useState('')
  const [pessoasSearchMapF219, setPessoasSearchMapF219] = useState('')
  const [pessoasSearchMapF220, setPessoasSearchMapF220] = useState('')
  const [pessoasSearchMapF221, setPessoasSearchMapF221] = useState('')
  const [pessoasSearchMapF222, setPessoasSearchMapF222] = useState('')




  // GALERIA G 

  const [pessoasSearchMapG101, setPessoasSearchMapG101] = useState('')
  const [pessoasSearchMapG102, setPessoasSearchMapG102] = useState('')
  const [pessoasSearchMapG103, setPessoasSearchMapG103] = useState('')
  const [pessoasSearchMapG104, setPessoasSearchMapG104] = useState('')
  const [pessoasSearchMapG105, setPessoasSearchMapG105] = useState('')
  const [pessoasSearchMapG106, setPessoasSearchMapG106] = useState('')
  const [pessoasSearchMapG107, setPessoasSearchMapG107] = useState('')
  const [pessoasSearchMapG108, setPessoasSearchMapG108] = useState('')
  const [pessoasSearchMapG109, setPessoasSearchMapG109] = useState('')
  const [pessoasSearchMapG110, setPessoasSearchMapG110] = useState('')
  const [pessoasSearchMapG111, setPessoasSearchMapG111] = useState('')
  const [pessoasSearchMapG112, setPessoasSearchMapG112] = useState('')
  const [pessoasSearchMapG113, setPessoasSearchMapG113] = useState('')
  const [pessoasSearchMapG114, setPessoasSearchMapG114] = useState('')
  const [pessoasSearchMapG115, setPessoasSearchMapG115] = useState('')
  const [pessoasSearchMapG116, setPessoasSearchMapG116] = useState('')
  const [pessoasSearchMapG117, setPessoasSearchMapG117] = useState('')
  const [pessoasSearchMapG118, setPessoasSearchMapG118] = useState('')
  const [pessoasSearchMapG119, setPessoasSearchMapG119] = useState('')
  const [pessoasSearchMapG120, setPessoasSearchMapG120] = useState('')
  const [pessoasSearchMapG121, setPessoasSearchMapG121] = useState('')
  const [pessoasSearchMapG122, setPessoasSearchMapG122] = useState('')
  const [pessoasSearchMapG201, setPessoasSearchMapG201] = useState('')
  const [pessoasSearchMapG202, setPessoasSearchMapG202] = useState('')
  const [pessoasSearchMapG203, setPessoasSearchMapG203] = useState('')
  const [pessoasSearchMapG204, setPessoasSearchMapG204] = useState('')
  const [pessoasSearchMapG205, setPessoasSearchMapG205] = useState('')
  const [pessoasSearchMapG206, setPessoasSearchMapG206] = useState('')
  const [pessoasSearchMapG207, setPessoasSearchMapG207] = useState('')
  const [pessoasSearchMapG208, setPessoasSearchMapG208] = useState('')
  const [pessoasSearchMapG209, setPessoasSearchMapG209] = useState('')
  const [pessoasSearchMapG210, setPessoasSearchMapG210] = useState('')
  const [pessoasSearchMapG211, setPessoasSearchMapG211] = useState('')
  const [pessoasSearchMapG212, setPessoasSearchMapG212] = useState('')
  const [pessoasSearchMapG213, setPessoasSearchMapG213] = useState('')
  const [pessoasSearchMapG214, setPessoasSearchMapG214] = useState('')
  const [pessoasSearchMapG215, setPessoasSearchMapG215] = useState('')
  const [pessoasSearchMapG216, setPessoasSearchMapG216] = useState('')
  const [pessoasSearchMapG217, setPessoasSearchMapG217] = useState('')
  const [pessoasSearchMapG218, setPessoasSearchMapG218] = useState('')
  const [pessoasSearchMapG219, setPessoasSearchMapG219] = useState('')
  const [pessoasSearchMapG220, setPessoasSearchMapG220] = useState('')
  const [pessoasSearchMapG221, setPessoasSearchMapG221] = useState('')
  const [pessoasSearchMapG222, setPessoasSearchMapG222] = useState('')

  //variaveis da triagem

  const [pessoasSearchMapTR201, setPessoasSearchMapTR201] = useState('')
  const [pessoasSearchMapTR202, setPessoasSearchMapTR202] = useState('')





  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE

  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')
  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA101total, setPessoasSearchMapA101total] = useState('')
  const [pessoasSearchMapA102total, setPessoasSearchMapA102total] = useState('')
  const [pessoasSearchMapA103total, setPessoasSearchMapA103total] = useState('')
  const [pessoasSearchMapA104total, setPessoasSearchMapA104total] = useState('')
  const [pessoasSearchMapA105total, setPessoasSearchMapA105total] = useState('')
  const [pessoasSearchMapA106total, setPessoasSearchMapA106total] = useState('')
  const [pessoasSearchMapA107total, setPessoasSearchMapA107total] = useState('')
  const [pessoasSearchMapA108total, setPessoasSearchMapA108total] = useState('')
  const [pessoasSearchMapA109total, setPessoasSearchMapA109total] = useState('')
  const [pessoasSearchMapA110total, setPessoasSearchMapA110total] = useState('')
  const [pessoasSearchMapA111total, setPessoasSearchMapA111total] = useState('')
  const [pessoasSearchMapA112total, setPessoasSearchMapA112total] = useState('')
  const [pessoasSearchMapA113total, setPessoasSearchMapA113total] = useState('')
  const [pessoasSearchMapA114total, setPessoasSearchMapA114total] = useState('')
  const [pessoasSearchMapA115total, setPessoasSearchMapA115total] = useState('')
  const [pessoasSearchMapA116total, setPessoasSearchMapA116total] = useState('')
  const [pessoasSearchMapA117total, setPessoasSearchMapA117total] = useState('')
  const [pessoasSearchMapA118total, setPessoasSearchMapA118total] = useState('')
  const [pessoasSearchMapA119total, setPessoasSearchMapA119total] = useState('')
  const [pessoasSearchMapA120total, setPessoasSearchMapA120total] = useState('')
  const [pessoasSearchMapA121total, setPessoasSearchMapA121total] = useState('')
  const [pessoasSearchMapA122total, setPessoasSearchMapA122total] = useState('')
  const [pessoasSearchMapA201total, setPessoasSearchMapA201total] = useState('')
  const [pessoasSearchMapA202total, setPessoasSearchMapA202total] = useState('')
  const [pessoasSearchMapA203total, setPessoasSearchMapA203total] = useState('')
  const [pessoasSearchMapA204total, setPessoasSearchMapA204total] = useState('')
  const [pessoasSearchMapA205total, setPessoasSearchMapA205total] = useState('')
  const [pessoasSearchMapA206total, setPessoasSearchMapA206total] = useState('')
  const [pessoasSearchMapA207total, setPessoasSearchMapA207total] = useState('')
  const [pessoasSearchMapA208total, setPessoasSearchMapA208total] = useState('')
  const [pessoasSearchMapA209total, setPessoasSearchMapA209total] = useState('')
  const [pessoasSearchMapA210total, setPessoasSearchMapA210total] = useState('')
  const [pessoasSearchMapA211total, setPessoasSearchMapA211total] = useState('')
  const [pessoasSearchMapA212total, setPessoasSearchMapA212total] = useState('')
  const [pessoasSearchMapA213total, setPessoasSearchMapA213total] = useState('')
  const [pessoasSearchMapA214total, setPessoasSearchMapA214total] = useState('')
  const [pessoasSearchMapA215total, setPessoasSearchMapA215total] = useState('')
  const [pessoasSearchMapA216total, setPessoasSearchMapA216total] = useState('')
  const [pessoasSearchMapA217total, setPessoasSearchMapA217total] = useState('')
  const [pessoasSearchMapA218total, setPessoasSearchMapA218total] = useState('')
  const [pessoasSearchMapA219total, setPessoasSearchMapA219total] = useState('')
  const [pessoasSearchMapA220total, setPessoasSearchMapA220total] = useState('')
  const [pessoasSearchMapA221total, setPessoasSearchMapA221total] = useState('')
  const [pessoasSearchMapA222total, setPessoasSearchMapA222total] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB101total, setPessoasSearchMapB101total] = useState('')
  const [pessoasSearchMapB102total, setPessoasSearchMapB102total] = useState('')
  const [pessoasSearchMapB103total, setPessoasSearchMapB103total] = useState('')
  const [pessoasSearchMapB104total, setPessoasSearchMapB104total] = useState('')
  const [pessoasSearchMapB105total, setPessoasSearchMapB105total] = useState('')
  const [pessoasSearchMapB106total, setPessoasSearchMapB106total] = useState('')
  const [pessoasSearchMapB107total, setPessoasSearchMapB107total] = useState('')
  const [pessoasSearchMapB108total, setPessoasSearchMapB108total] = useState('')
  const [pessoasSearchMapB109total, setPessoasSearchMapB109total] = useState('')
  const [pessoasSearchMapB110total, setPessoasSearchMapB110total] = useState('')
  const [pessoasSearchMapB111total, setPessoasSearchMapB111total] = useState('')
  const [pessoasSearchMapB112total, setPessoasSearchMapB112total] = useState('')
  const [pessoasSearchMapB113total, setPessoasSearchMapB113total] = useState('')
  const [pessoasSearchMapB114total, setPessoasSearchMapB114total] = useState('')
  const [pessoasSearchMapB115total, setPessoasSearchMapB115total] = useState('')
  const [pessoasSearchMapB116total, setPessoasSearchMapB116total] = useState('')
  const [pessoasSearchMapB117total, setPessoasSearchMapB117total] = useState('')
  const [pessoasSearchMapB118total, setPessoasSearchMapB118total] = useState('')
  const [pessoasSearchMapB119total, setPessoasSearchMapB119total] = useState('')
  const [pessoasSearchMapB120total, setPessoasSearchMapB120total] = useState('')
  const [pessoasSearchMapB121total, setPessoasSearchMapB121total] = useState('')
  const [pessoasSearchMapB122total, setPessoasSearchMapB122total] = useState('')
  const [pessoasSearchMapB201total, setPessoasSearchMapB201total] = useState('')
  const [pessoasSearchMapB202total, setPessoasSearchMapB202total] = useState('')
  const [pessoasSearchMapB203total, setPessoasSearchMapB203total] = useState('')
  const [pessoasSearchMapB204total, setPessoasSearchMapB204total] = useState('')
  const [pessoasSearchMapB205total, setPessoasSearchMapB205total] = useState('')
  const [pessoasSearchMapB206total, setPessoasSearchMapB206total] = useState('')
  const [pessoasSearchMapB207total, setPessoasSearchMapB207total] = useState('')
  const [pessoasSearchMapB208total, setPessoasSearchMapB208total] = useState('')
  const [pessoasSearchMapB209total, setPessoasSearchMapB209total] = useState('')
  const [pessoasSearchMapB210total, setPessoasSearchMapB210total] = useState('')
  const [pessoasSearchMapB211total, setPessoasSearchMapB211total] = useState('')
  const [pessoasSearchMapB212total, setPessoasSearchMapB212total] = useState('')
  const [pessoasSearchMapB213total, setPessoasSearchMapB213total] = useState('')
  const [pessoasSearchMapB214total, setPessoasSearchMapB214total] = useState('')
  const [pessoasSearchMapB215total, setPessoasSearchMapB215total] = useState('')
  const [pessoasSearchMapB216total, setPessoasSearchMapB216total] = useState('')
  const [pessoasSearchMapB217total, setPessoasSearchMapB217total] = useState('')
  const [pessoasSearchMapB218total, setPessoasSearchMapB218total] = useState('')
  const [pessoasSearchMapB219total, setPessoasSearchMapB219total] = useState('')
  const [pessoasSearchMapB220total, setPessoasSearchMapB220total] = useState('')
  const [pessoasSearchMapB221total, setPessoasSearchMapB221total] = useState('')
  const [pessoasSearchMapB222total, setPessoasSearchMapB222total] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC101total, setPessoasSearchMapC101total] = useState('')
  const [pessoasSearchMapC102total, setPessoasSearchMapC102total] = useState('')
  const [pessoasSearchMapC103total, setPessoasSearchMapC103total] = useState('')
  const [pessoasSearchMapC104total, setPessoasSearchMapC104total] = useState('')
  const [pessoasSearchMapC105total, setPessoasSearchMapC105total] = useState('')
  const [pessoasSearchMapC106total, setPessoasSearchMapC106total] = useState('')
  const [pessoasSearchMapC107total, setPessoasSearchMapC107total] = useState('')
  const [pessoasSearchMapC108total, setPessoasSearchMapC108total] = useState('')
  const [pessoasSearchMapC109total, setPessoasSearchMapC109total] = useState('')
  const [pessoasSearchMapC110total, setPessoasSearchMapC110total] = useState('')
  const [pessoasSearchMapC111total, setPessoasSearchMapC111total] = useState('')
  const [pessoasSearchMapC112total, setPessoasSearchMapC112total] = useState('')
  const [pessoasSearchMapC113total, setPessoasSearchMapC113total] = useState('')
  const [pessoasSearchMapC114total, setPessoasSearchMapC114total] = useState('')
  const [pessoasSearchMapC115total, setPessoasSearchMapC115total] = useState('')
  const [pessoasSearchMapC116total, setPessoasSearchMapC116total] = useState('')
  const [pessoasSearchMapC117total, setPessoasSearchMapC117total] = useState('')
  const [pessoasSearchMapC118total, setPessoasSearchMapC118total] = useState('')
  const [pessoasSearchMapC119total, setPessoasSearchMapC119total] = useState('')
  const [pessoasSearchMapC120total, setPessoasSearchMapC120total] = useState('')
  const [pessoasSearchMapC121total, setPessoasSearchMapC121total] = useState('')
  const [pessoasSearchMapC122total, setPessoasSearchMapC122total] = useState('')
  const [pessoasSearchMapC201total, setPessoasSearchMapC201total] = useState('')
  const [pessoasSearchMapC202total, setPessoasSearchMapC202total] = useState('')
  const [pessoasSearchMapC203total, setPessoasSearchMapC203total] = useState('')
  const [pessoasSearchMapC204total, setPessoasSearchMapC204total] = useState('')
  const [pessoasSearchMapC205total, setPessoasSearchMapC205total] = useState('')
  const [pessoasSearchMapC206total, setPessoasSearchMapC206total] = useState('')
  const [pessoasSearchMapC207total, setPessoasSearchMapC207total] = useState('')
  const [pessoasSearchMapC208total, setPessoasSearchMapC208total] = useState('')
  const [pessoasSearchMapC209total, setPessoasSearchMapC209total] = useState('')
  const [pessoasSearchMapC210total, setPessoasSearchMapC210total] = useState('')
  const [pessoasSearchMapC211total, setPessoasSearchMapC211total] = useState('')
  const [pessoasSearchMapC212total, setPessoasSearchMapC212total] = useState('')
  const [pessoasSearchMapC213total, setPessoasSearchMapC213total] = useState('')
  const [pessoasSearchMapC214total, setPessoasSearchMapC214total] = useState('')
  const [pessoasSearchMapC215total, setPessoasSearchMapC215total] = useState('')
  const [pessoasSearchMapC216total, setPessoasSearchMapC216total] = useState('')
  const [pessoasSearchMapC217total, setPessoasSearchMapC217total] = useState('')
  const [pessoasSearchMapC218total, setPessoasSearchMapC218total] = useState('')
  const [pessoasSearchMapC219total, setPessoasSearchMapC219total] = useState('')
  const [pessoasSearchMapC220total, setPessoasSearchMapC220total] = useState('')
  const [pessoasSearchMapC221total, setPessoasSearchMapC221total] = useState('')
  const [pessoasSearchMapC222total, setPessoasSearchMapC222total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapD101total, setPessoasSearchMapD101total] = useState('')
  const [pessoasSearchMapD102total, setPessoasSearchMapD102total] = useState('')
  const [pessoasSearchMapD103total, setPessoasSearchMapD103total] = useState('')
  const [pessoasSearchMapD104total, setPessoasSearchMapD104total] = useState('')
  const [pessoasSearchMapD105total, setPessoasSearchMapD105total] = useState('')
  const [pessoasSearchMapD106total, setPessoasSearchMapD106total] = useState('')
  const [pessoasSearchMapD107total, setPessoasSearchMapD107total] = useState('')
  const [pessoasSearchMapD108total, setPessoasSearchMapD108total] = useState('')
  const [pessoasSearchMapD109total, setPessoasSearchMapD109total] = useState('')
  const [pessoasSearchMapD110total, setPessoasSearchMapD110total] = useState('')
  const [pessoasSearchMapD111total, setPessoasSearchMapD111total] = useState('')
  const [pessoasSearchMapD112total, setPessoasSearchMapD112total] = useState('')
  const [pessoasSearchMapD113total, setPessoasSearchMapD113total] = useState('')
  const [pessoasSearchMapD114total, setPessoasSearchMapD114total] = useState('')
  const [pessoasSearchMapD115total, setPessoasSearchMapD115total] = useState('')
  const [pessoasSearchMapD116total, setPessoasSearchMapD116total] = useState('')
  const [pessoasSearchMapD117total, setPessoasSearchMapD117total] = useState('')
  const [pessoasSearchMapD118total, setPessoasSearchMapD118total] = useState('')
  const [pessoasSearchMapD119total, setPessoasSearchMapD119total] = useState('')
  const [pessoasSearchMapD120total, setPessoasSearchMapD120total] = useState('')
  const [pessoasSearchMapD121total, setPessoasSearchMapD121total] = useState('')
  const [pessoasSearchMapD122total, setPessoasSearchMapD122total] = useState('')
  const [pessoasSearchMapD201total, setPessoasSearchMapD201total] = useState('')
  const [pessoasSearchMapD202total, setPessoasSearchMapD202total] = useState('')
  const [pessoasSearchMapD203total, setPessoasSearchMapD203total] = useState('')
  const [pessoasSearchMapD204total, setPessoasSearchMapD204total] = useState('')
  const [pessoasSearchMapD205total, setPessoasSearchMapD205total] = useState('')
  const [pessoasSearchMapD206total, setPessoasSearchMapD206total] = useState('')
  const [pessoasSearchMapD207total, setPessoasSearchMapD207total] = useState('')
  const [pessoasSearchMapD208total, setPessoasSearchMapD208total] = useState('')
  const [pessoasSearchMapD209total, setPessoasSearchMapD209total] = useState('')
  const [pessoasSearchMapD210total, setPessoasSearchMapD210total] = useState('')
  const [pessoasSearchMapD211total, setPessoasSearchMapD211total] = useState('')
  const [pessoasSearchMapD212total, setPessoasSearchMapD212total] = useState('')
  const [pessoasSearchMapD213total, setPessoasSearchMapD213total] = useState('')
  const [pessoasSearchMapD214total, setPessoasSearchMapD214total] = useState('')
  const [pessoasSearchMapD215total, setPessoasSearchMapD215total] = useState('')
  const [pessoasSearchMapD216total, setPessoasSearchMapD216total] = useState('')
  const [pessoasSearchMapD217total, setPessoasSearchMapD217total] = useState('')
  const [pessoasSearchMapD218total, setPessoasSearchMapD218total] = useState('')
  const [pessoasSearchMapD219total, setPessoasSearchMapD219total] = useState('')
  const [pessoasSearchMapD220total, setPessoasSearchMapD220total] = useState('')
  const [pessoasSearchMapD221total, setPessoasSearchMapD221total] = useState('')
  const [pessoasSearchMapD222total, setPessoasSearchMapD222total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA E

  const [pessoasSearchMapE101total, setPessoasSearchMapE101total] = useState('')
  const [pessoasSearchMapE102total, setPessoasSearchMapE102total] = useState('')
  const [pessoasSearchMapE103total, setPessoasSearchMapE103total] = useState('')
  const [pessoasSearchMapE104total, setPessoasSearchMapE104total] = useState('')
  const [pessoasSearchMapE105total, setPessoasSearchMapE105total] = useState('')
  const [pessoasSearchMapE106total, setPessoasSearchMapE106total] = useState('')
  const [pessoasSearchMapE107total, setPessoasSearchMapE107total] = useState('')
  const [pessoasSearchMapE108total, setPessoasSearchMapE108total] = useState('')
  const [pessoasSearchMapE109total, setPessoasSearchMapE109total] = useState('')
  const [pessoasSearchMapE110total, setPessoasSearchMapE110total] = useState('')
  const [pessoasSearchMapE111total, setPessoasSearchMapE111total] = useState('')
  const [pessoasSearchMapE112total, setPessoasSearchMapE112total] = useState('')
  const [pessoasSearchMapE113total, setPessoasSearchMapE113total] = useState('')
  const [pessoasSearchMapE114total, setPessoasSearchMapE114total] = useState('')
  const [pessoasSearchMapE115total, setPessoasSearchMapE115total] = useState('')
  const [pessoasSearchMapE116total, setPessoasSearchMapE116total] = useState('')
  const [pessoasSearchMapE117total, setPessoasSearchMapE117total] = useState('')
  const [pessoasSearchMapE118total, setPessoasSearchMapE118total] = useState('')
  const [pessoasSearchMapE119total, setPessoasSearchMapE119total] = useState('')
  const [pessoasSearchMapE120total, setPessoasSearchMapE120total] = useState('')
  const [pessoasSearchMapE121total, setPessoasSearchMapE121total] = useState('')
  const [pessoasSearchMapE122total, setPessoasSearchMapE122total] = useState('')
  const [pessoasSearchMapE201total, setPessoasSearchMapE201total] = useState('')
  const [pessoasSearchMapE202total, setPessoasSearchMapE202total] = useState('')
  const [pessoasSearchMapE203total, setPessoasSearchMapE203total] = useState('')
  const [pessoasSearchMapE204total, setPessoasSearchMapE204total] = useState('')
  const [pessoasSearchMapE205total, setPessoasSearchMapE205total] = useState('')
  const [pessoasSearchMapE206total, setPessoasSearchMapE206total] = useState('')
  const [pessoasSearchMapE207total, setPessoasSearchMapE207total] = useState('')
  const [pessoasSearchMapE208total, setPessoasSearchMapE208total] = useState('')
  const [pessoasSearchMapE209total, setPessoasSearchMapE209total] = useState('')
  const [pessoasSearchMapE210total, setPessoasSearchMapE210total] = useState('')
  const [pessoasSearchMapE211total, setPessoasSearchMapE211total] = useState('')
  const [pessoasSearchMapE212total, setPessoasSearchMapE212total] = useState('')
  const [pessoasSearchMapE213total, setPessoasSearchMapE213total] = useState('')
  const [pessoasSearchMapE214total, setPessoasSearchMapE214total] = useState('')
  const [pessoasSearchMapE215total, setPessoasSearchMapE215total] = useState('')
  const [pessoasSearchMapE216total, setPessoasSearchMapE216total] = useState('')
  const [pessoasSearchMapE217total, setPessoasSearchMapE217total] = useState('')
  const [pessoasSearchMapE218total, setPessoasSearchMapE218total] = useState('')
  const [pessoasSearchMapE219total, setPessoasSearchMapE219total] = useState('')
  const [pessoasSearchMapE220total, setPessoasSearchMapE220total] = useState('')
  const [pessoasSearchMapE221total, setPessoasSearchMapE221total] = useState('')
  const [pessoasSearchMapE222total, setPessoasSearchMapE222total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA E

  const [pessoasSearchMapF101total, setPessoasSearchMapF101total] = useState('')
  const [pessoasSearchMapF102total, setPessoasSearchMapF102total] = useState('')
  const [pessoasSearchMapF103total, setPessoasSearchMapF103total] = useState('')
  const [pessoasSearchMapF104total, setPessoasSearchMapF104total] = useState('')
  const [pessoasSearchMapF105total, setPessoasSearchMapF105total] = useState('')
  const [pessoasSearchMapF106total, setPessoasSearchMapF106total] = useState('')
  const [pessoasSearchMapF107total, setPessoasSearchMapF107total] = useState('')
  const [pessoasSearchMapF108total, setPessoasSearchMapF108total] = useState('')
  const [pessoasSearchMapF109total, setPessoasSearchMapF109total] = useState('')
  const [pessoasSearchMapF110total, setPessoasSearchMapF110total] = useState('')
  const [pessoasSearchMapF111total, setPessoasSearchMapF111total] = useState('')
  const [pessoasSearchMapF112total, setPessoasSearchMapF112total] = useState('')
  const [pessoasSearchMapF113total, setPessoasSearchMapF113total] = useState('')
  const [pessoasSearchMapF114total, setPessoasSearchMapF114total] = useState('')
  const [pessoasSearchMapF115total, setPessoasSearchMapF115total] = useState('')
  const [pessoasSearchMapF116total, setPessoasSearchMapF116total] = useState('')
  const [pessoasSearchMapF117total, setPessoasSearchMapF117total] = useState('')
  const [pessoasSearchMapF118total, setPessoasSearchMapF118total] = useState('')
  const [pessoasSearchMapF119total, setPessoasSearchMapF119total] = useState('')
  const [pessoasSearchMapF120total, setPessoasSearchMapF120total] = useState('')
  const [pessoasSearchMapF121total, setPessoasSearchMapF121total] = useState('')
  const [pessoasSearchMapF122total, setPessoasSearchMapF122total] = useState('')
  const [pessoasSearchMapF201total, setPessoasSearchMapF201total] = useState('')
  const [pessoasSearchMapF202total, setPessoasSearchMapF202total] = useState('')
  const [pessoasSearchMapF203total, setPessoasSearchMapF203total] = useState('')
  const [pessoasSearchMapF204total, setPessoasSearchMapF204total] = useState('')
  const [pessoasSearchMapF205total, setPessoasSearchMapF205total] = useState('')
  const [pessoasSearchMapF206total, setPessoasSearchMapF206total] = useState('')
  const [pessoasSearchMapF207total, setPessoasSearchMapF207total] = useState('')
  const [pessoasSearchMapF208total, setPessoasSearchMapF208total] = useState('')
  const [pessoasSearchMapF209total, setPessoasSearchMapF209total] = useState('')
  const [pessoasSearchMapF210total, setPessoasSearchMapF210total] = useState('')
  const [pessoasSearchMapF211total, setPessoasSearchMapF211total] = useState('')
  const [pessoasSearchMapF212total, setPessoasSearchMapF212total] = useState('')
  const [pessoasSearchMapF213total, setPessoasSearchMapF213total] = useState('')
  const [pessoasSearchMapF214total, setPessoasSearchMapF214total] = useState('')
  const [pessoasSearchMapF215total, setPessoasSearchMapF215total] = useState('')
  const [pessoasSearchMapF216total, setPessoasSearchMapF216total] = useState('')
  const [pessoasSearchMapF217total, setPessoasSearchMapF217total] = useState('')
  const [pessoasSearchMapF218total, setPessoasSearchMapF218total] = useState('')
  const [pessoasSearchMapF219total, setPessoasSearchMapF219total] = useState('')
  const [pessoasSearchMapF220total, setPessoasSearchMapF220total] = useState('')
  const [pessoasSearchMapF221total, setPessoasSearchMapF221total] = useState('')
  const [pessoasSearchMapF222total, setPessoasSearchMapF222total] = useState('')

  //GALERIA G 


  const [pessoasSearchMapG101total, setPessoasSearchMapG101total] = useState('')
  const [pessoasSearchMapG102total, setPessoasSearchMapG102total] = useState('')
  const [pessoasSearchMapG103total, setPessoasSearchMapG103total] = useState('')
  const [pessoasSearchMapG104total, setPessoasSearchMapG104total] = useState('')
  const [pessoasSearchMapG105total, setPessoasSearchMapG105total] = useState('')
  const [pessoasSearchMapG106total, setPessoasSearchMapG106total] = useState('')
  const [pessoasSearchMapG107total, setPessoasSearchMapG107total] = useState('')
  const [pessoasSearchMapG108total, setPessoasSearchMapG108total] = useState('')
  const [pessoasSearchMapG109total, setPessoasSearchMapG109total] = useState('')
  const [pessoasSearchMapG110total, setPessoasSearchMapG110total] = useState('')
  const [pessoasSearchMapG111total, setPessoasSearchMapG111total] = useState('')
  const [pessoasSearchMapG112total, setPessoasSearchMapG112total] = useState('')
  const [pessoasSearchMapG113total, setPessoasSearchMapG113total] = useState('')
  const [pessoasSearchMapG114total, setPessoasSearchMapG114total] = useState('')
  const [pessoasSearchMapG115total, setPessoasSearchMapG115total] = useState('')
  const [pessoasSearchMapG116total, setPessoasSearchMapG116total] = useState('')
  const [pessoasSearchMapG117total, setPessoasSearchMapG117total] = useState('')
  const [pessoasSearchMapG118total, setPessoasSearchMapG118total] = useState('')
  const [pessoasSearchMapG119total, setPessoasSearchMapG119total] = useState('')
  const [pessoasSearchMapG120total, setPessoasSearchMapG120total] = useState('')
  const [pessoasSearchMapG121total, setPessoasSearchMapG121total] = useState('')
  const [pessoasSearchMapG122total, setPessoasSearchMapG122total] = useState('')
  const [pessoasSearchMapG201total, setPessoasSearchMapG201total] = useState('')
  const [pessoasSearchMapG202total, setPessoasSearchMapG202total] = useState('')
  const [pessoasSearchMapG203total, setPessoasSearchMapG203total] = useState('')
  const [pessoasSearchMapG204total, setPessoasSearchMapG204total] = useState('')
  const [pessoasSearchMapG205total, setPessoasSearchMapG205total] = useState('')
  const [pessoasSearchMapG206total, setPessoasSearchMapG206total] = useState('')
  const [pessoasSearchMapG207total, setPessoasSearchMapG207total] = useState('')
  const [pessoasSearchMapG208total, setPessoasSearchMapG208total] = useState('')
  const [pessoasSearchMapG209total, setPessoasSearchMapG209total] = useState('')
  const [pessoasSearchMapG210total, setPessoasSearchMapG210total] = useState('')
  const [pessoasSearchMapG211total, setPessoasSearchMapG211total] = useState('')
  const [pessoasSearchMapG212total, setPessoasSearchMapG212total] = useState('')
  const [pessoasSearchMapG213total, setPessoasSearchMapG213total] = useState('')
  const [pessoasSearchMapG214total, setPessoasSearchMapG214total] = useState('')
  const [pessoasSearchMapG215total, setPessoasSearchMapG215total] = useState('')
  const [pessoasSearchMapG216total, setPessoasSearchMapG216total] = useState('')
  const [pessoasSearchMapG217total, setPessoasSearchMapG217total] = useState('')
  const [pessoasSearchMapG218total, setPessoasSearchMapG218total] = useState('')
  const [pessoasSearchMapG219total, setPessoasSearchMapG219total] = useState('')
  const [pessoasSearchMapG220total, setPessoasSearchMapG220total] = useState('')
  const [pessoasSearchMapG221total, setPessoasSearchMapG221total] = useState('')
  const [pessoasSearchMapG222total, setPessoasSearchMapG222total] = useState('')

  //variaveis da triagem

  const [pessoasSearchMapTR201total, setPessoasSearchMapTR201total] = useState('')
  const [pessoasSearchMapTR202total, setPessoasSearchMapTR202total] = useState('')


  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA101total +
    pessoasSearchMapA102total +
    pessoasSearchMapA103total +
    pessoasSearchMapA104total +
    pessoasSearchMapA105total +
    pessoasSearchMapA106total +
    pessoasSearchMapA107total +
    pessoasSearchMapA108total +
    pessoasSearchMapA109total +
    pessoasSearchMapA110total +
    pessoasSearchMapA111total +
    pessoasSearchMapA112total +
    pessoasSearchMapA113total +
    pessoasSearchMapA114total +
    pessoasSearchMapA115total +
    pessoasSearchMapA116total +
    pessoasSearchMapA117total +
    pessoasSearchMapA118total +
    pessoasSearchMapA119total +
    pessoasSearchMapA120total +
    pessoasSearchMapA121total +
    pessoasSearchMapA122total +
    pessoasSearchMapA201total +
    pessoasSearchMapA202total +
    pessoasSearchMapA203total +
    pessoasSearchMapA204total +
    pessoasSearchMapA205total +
    pessoasSearchMapA206total +
    pessoasSearchMapA207total +
    pessoasSearchMapA208total +
    pessoasSearchMapA209total +
    pessoasSearchMapA210total +
    pessoasSearchMapA211total +
    pessoasSearchMapA212total +
    pessoasSearchMapA213total +
    pessoasSearchMapA214total +
    pessoasSearchMapA215total +
    pessoasSearchMapA216total +
    pessoasSearchMapA217total +
    pessoasSearchMapA218total +
    pessoasSearchMapA219total +
    pessoasSearchMapA220total +
    pessoasSearchMapA221total +
    pessoasSearchMapA222total


  const totalQuadrante1A =

    pessoasSearchMapA101total +
    pessoasSearchMapA102total +
    pessoasSearchMapA103total +
    pessoasSearchMapA104total +
    pessoasSearchMapA105total +
    pessoasSearchMapA106total +
    pessoasSearchMapA107total +
    pessoasSearchMapA108total +
    pessoasSearchMapA109total +
    pessoasSearchMapA110total +
    pessoasSearchMapA111total +
    pessoasSearchMapA112total

  const totalQuadrante2A =
    pessoasSearchMapA113total +
    pessoasSearchMapA114total +
    pessoasSearchMapA115total +
    pessoasSearchMapA116total +
    pessoasSearchMapA117total +
    pessoasSearchMapA118total +
    pessoasSearchMapA119total +
    pessoasSearchMapA120total +
    pessoasSearchMapA121total +
    pessoasSearchMapA122total

  const totalQuadrante3A =
    pessoasSearchMapA201total +
    pessoasSearchMapA202total +
    pessoasSearchMapA203total +
    pessoasSearchMapA204total +
    pessoasSearchMapA205total +
    pessoasSearchMapA206total +
    pessoasSearchMapA207total +
    pessoasSearchMapA208total +
    pessoasSearchMapA209total +
    pessoasSearchMapA210total +
    pessoasSearchMapA211total +
    pessoasSearchMapA212total

  const totalQuadrante4A =
    pessoasSearchMapA213total +
    pessoasSearchMapA214total +
    pessoasSearchMapA215total +
    pessoasSearchMapA216total +
    pessoasSearchMapA217total +
    pessoasSearchMapA218total +
    pessoasSearchMapA219total +
    pessoasSearchMapA220total +
    pessoasSearchMapA221total +
    pessoasSearchMapA222total

  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB101total +
    pessoasSearchMapB102total +
    pessoasSearchMapB103total +
    pessoasSearchMapB104total +
    pessoasSearchMapB105total +
    pessoasSearchMapB106total +
    pessoasSearchMapB107total +
    pessoasSearchMapB108total +
    pessoasSearchMapB109total +
    pessoasSearchMapB110total +
    pessoasSearchMapB111total +
    pessoasSearchMapB112total +
    pessoasSearchMapB113total +
    pessoasSearchMapB114total +
    pessoasSearchMapB115total +
    pessoasSearchMapB116total +
    pessoasSearchMapB117total +
    pessoasSearchMapB118total +
    pessoasSearchMapB119total +
    pessoasSearchMapB120total +
    pessoasSearchMapB121total +
    pessoasSearchMapB122total +
    pessoasSearchMapB201total +
    pessoasSearchMapB202total +
    pessoasSearchMapB203total +
    pessoasSearchMapB204total +
    pessoasSearchMapB205total +
    pessoasSearchMapB206total +
    pessoasSearchMapB207total +
    pessoasSearchMapB208total +
    pessoasSearchMapB209total +
    pessoasSearchMapB210total +
    pessoasSearchMapB211total +
    pessoasSearchMapB212total +
    pessoasSearchMapB213total +
    pessoasSearchMapB214total +
    pessoasSearchMapB215total +
    pessoasSearchMapB216total +
    pessoasSearchMapB217total +
    pessoasSearchMapB218total +
    pessoasSearchMapB219total +
    pessoasSearchMapB220total +
    pessoasSearchMapB221total +
    pessoasSearchMapB222total

  // QUADRANTES BRAVO 

  const totalQuadrante1B =

    pessoasSearchMapB101total +
    pessoasSearchMapB102total +
    pessoasSearchMapB103total +
    pessoasSearchMapB104total +
    pessoasSearchMapB105total +
    pessoasSearchMapB106total +
    pessoasSearchMapB107total +
    pessoasSearchMapB108total +
    pessoasSearchMapB109total +
    pessoasSearchMapB110total +
    pessoasSearchMapB111total +
    pessoasSearchMapB112total

  const totalQuadrante2B =
    pessoasSearchMapB113total +
    pessoasSearchMapB114total +
    pessoasSearchMapB115total +
    pessoasSearchMapB116total +
    pessoasSearchMapB117total +
    pessoasSearchMapB118total +
    pessoasSearchMapB119total +
    pessoasSearchMapB120total +
    pessoasSearchMapB121total +
    pessoasSearchMapB122total

  const totalQuadrante3B =
    pessoasSearchMapB201total +
    pessoasSearchMapB202total +
    pessoasSearchMapB203total +
    pessoasSearchMapB204total +
    pessoasSearchMapB205total +
    pessoasSearchMapB206total +
    pessoasSearchMapB207total +
    pessoasSearchMapB208total +
    pessoasSearchMapB209total +
    pessoasSearchMapB210total +
    pessoasSearchMapB211total +
    pessoasSearchMapB212total

  const totalQuadrante4B =
    pessoasSearchMapB213total +
    pessoasSearchMapB214total +
    pessoasSearchMapB215total +
    pessoasSearchMapB216total +
    pessoasSearchMapB217total +
    pessoasSearchMapB218total +
    pessoasSearchMapB219total +
    pessoasSearchMapB220total +
    pessoasSearchMapB221total +
    pessoasSearchMapB222total

  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC101total +
    pessoasSearchMapC102total +
    pessoasSearchMapC103total +
    pessoasSearchMapC104total +
    pessoasSearchMapC105total +
    pessoasSearchMapC106total +
    pessoasSearchMapC107total +
    pessoasSearchMapC108total +
    pessoasSearchMapC109total +
    pessoasSearchMapC110total +
    pessoasSearchMapC111total +
    pessoasSearchMapC112total +
    pessoasSearchMapC113total +
    pessoasSearchMapC114total +
    pessoasSearchMapC115total +
    pessoasSearchMapC116total +
    pessoasSearchMapC117total +
    pessoasSearchMapC118total +
    pessoasSearchMapC119total +
    pessoasSearchMapC120total +
    pessoasSearchMapC121total +
    pessoasSearchMapC122total +
    pessoasSearchMapC201total +
    pessoasSearchMapC202total +
    pessoasSearchMapC203total +
    pessoasSearchMapC204total +
    pessoasSearchMapC205total +
    pessoasSearchMapC206total +
    pessoasSearchMapC207total +
    pessoasSearchMapC208total +
    pessoasSearchMapC209total +
    pessoasSearchMapC210total +
    pessoasSearchMapC211total +
    pessoasSearchMapC212total +
    pessoasSearchMapC213total +
    pessoasSearchMapC214total +
    pessoasSearchMapC215total +
    pessoasSearchMapC216total +
    pessoasSearchMapC217total +
    pessoasSearchMapC218total +
    pessoasSearchMapC219total +
    pessoasSearchMapC220total +
    pessoasSearchMapC221total +
    pessoasSearchMapC222total

  // QUADRANTES CHARLIE 

  const totalQuadrante1C =

    pessoasSearchMapC101total +
    pessoasSearchMapC102total +
    pessoasSearchMapC103total +
    pessoasSearchMapC104total +
    pessoasSearchMapC105total +
    pessoasSearchMapC106total +
    pessoasSearchMapC107total +
    pessoasSearchMapC108total +
    pessoasSearchMapC109total +
    pessoasSearchMapC110total +
    pessoasSearchMapC111total +
    pessoasSearchMapC112total +
    pessoasSearchMapC113total +
    pessoasSearchMapC114total

  const totalQuadrante2C =
    pessoasSearchMapC113total +
    pessoasSearchMapC114total +
    pessoasSearchMapC115total +
    pessoasSearchMapC116total +
    pessoasSearchMapC117total +
    pessoasSearchMapC118total +
    pessoasSearchMapC119total +
    pessoasSearchMapC120total +
    pessoasSearchMapC121total +
    pessoasSearchMapC122total

  const totalQuadrante3C =
    pessoasSearchMapC201total +
    pessoasSearchMapC202total +
    pessoasSearchMapC203total +
    pessoasSearchMapC204total +
    pessoasSearchMapC205total +
    pessoasSearchMapC206total +
    pessoasSearchMapC207total +
    pessoasSearchMapC208total +
    pessoasSearchMapC209total +
    pessoasSearchMapC210total +
    pessoasSearchMapC211total +
    pessoasSearchMapC212total +
    pessoasSearchMapC213total +
    pessoasSearchMapC214total 

  const totalQuadrante4C =
    
    pessoasSearchMapC215total +
    pessoasSearchMapC216total +
    pessoasSearchMapC217total +
    pessoasSearchMapC218total +
    pessoasSearchMapC219total +
    pessoasSearchMapC220total +
    pessoasSearchMapC221total +
    pessoasSearchMapC222total



  const totalSomaD =
    pessoasSearchMapD101total +
    pessoasSearchMapD102total +
    pessoasSearchMapD103total +
    pessoasSearchMapD104total +
    pessoasSearchMapD105total +
    pessoasSearchMapD106total +
    pessoasSearchMapD107total +
    pessoasSearchMapD108total +
    pessoasSearchMapD109total +
    pessoasSearchMapD110total +
    pessoasSearchMapD111total +
    pessoasSearchMapD112total +
    pessoasSearchMapD113total +
    pessoasSearchMapD114total +
    pessoasSearchMapD115total +
    pessoasSearchMapD116total +
    pessoasSearchMapD117total +
    pessoasSearchMapD118total +
    pessoasSearchMapD119total +
    pessoasSearchMapD120total +
    pessoasSearchMapD121total +
    pessoasSearchMapD122total +
    pessoasSearchMapD201total +
    pessoasSearchMapD202total +
    pessoasSearchMapD203total +
    pessoasSearchMapD204total +
    pessoasSearchMapD205total +
    pessoasSearchMapD206total +
    pessoasSearchMapD207total +
    pessoasSearchMapD208total +
    pessoasSearchMapD209total +
    pessoasSearchMapD210total +
    pessoasSearchMapD211total +
    pessoasSearchMapD212total +
    pessoasSearchMapD213total +
    pessoasSearchMapD214total +
    pessoasSearchMapD215total +
    pessoasSearchMapD216total +
    pessoasSearchMapD217total +
    pessoasSearchMapD218total +
    pessoasSearchMapD219total +
    pessoasSearchMapD220total +
    pessoasSearchMapD221total +
    pessoasSearchMapD222total


  const totalQuadrante1D =

    pessoasSearchMapD101total +
    pessoasSearchMapD102total +
    pessoasSearchMapD103total +
    pessoasSearchMapD104total +
    pessoasSearchMapD105total +
    pessoasSearchMapD106total +
    pessoasSearchMapD107total +
    pessoasSearchMapD108total +
    pessoasSearchMapD109total +
    pessoasSearchMapD110total +
    pessoasSearchMapD111total +
    pessoasSearchMapD112total +
    pessoasSearchMapD113total +
    pessoasSearchMapD114total 

  const totalQuadrante2D =
    pessoasSearchMapD113total +
    pessoasSearchMapD114total +
    pessoasSearchMapD115total +
    pessoasSearchMapD116total +
    pessoasSearchMapD117total +
    pessoasSearchMapD118total +
    pessoasSearchMapD119total +
    pessoasSearchMapD120total +
    pessoasSearchMapD121total +
    pessoasSearchMapD122total

  const totalQuadrante3D =
    pessoasSearchMapD201total +
    pessoasSearchMapD202total +
    pessoasSearchMapD203total +
    pessoasSearchMapD204total +
    pessoasSearchMapD205total +
    pessoasSearchMapD206total +
    pessoasSearchMapD207total +
    pessoasSearchMapD208total +
    pessoasSearchMapD209total +
    pessoasSearchMapD210total +
    pessoasSearchMapD211total +
    pessoasSearchMapD212total +    
    pessoasSearchMapD213total +
    pessoasSearchMapD214total

  const totalQuadrante4D =

    pessoasSearchMapD215total +
    pessoasSearchMapD216total +
    pessoasSearchMapD217total +
    pessoasSearchMapD218total +
    pessoasSearchMapD219total +
    pessoasSearchMapD220total +
    pessoasSearchMapD221total +
    pessoasSearchMapD222total


  const totalSomaE =
    pessoasSearchMapE101total +
    pessoasSearchMapE102total +
    pessoasSearchMapE103total +
    pessoasSearchMapE104total +
    pessoasSearchMapE105total +
    pessoasSearchMapE106total +
    pessoasSearchMapE107total +
    pessoasSearchMapE108total +
    pessoasSearchMapE109total +
    pessoasSearchMapE110total +
    pessoasSearchMapE111total +
    pessoasSearchMapE112total +
    pessoasSearchMapE113total +
    pessoasSearchMapE114total +
    pessoasSearchMapE115total +
    pessoasSearchMapE116total +
    pessoasSearchMapE117total +
    pessoasSearchMapE118total +
    pessoasSearchMapE119total +
    pessoasSearchMapE120total +
    pessoasSearchMapE121total +
    pessoasSearchMapE122total +
    pessoasSearchMapE201total +
    pessoasSearchMapE202total +
    pessoasSearchMapE203total +
    pessoasSearchMapE204total +
    pessoasSearchMapE205total +
    pessoasSearchMapE206total +
    pessoasSearchMapE207total +
    pessoasSearchMapE208total +
    pessoasSearchMapE209total +
    pessoasSearchMapE210total +
    pessoasSearchMapE211total +
    pessoasSearchMapE212total +
    pessoasSearchMapE213total +
    pessoasSearchMapE214total +
    pessoasSearchMapE215total +
    pessoasSearchMapE216total +
    pessoasSearchMapE217total +
    pessoasSearchMapE218total +
    pessoasSearchMapE219total +
    pessoasSearchMapE220total +
    pessoasSearchMapE221total +
    pessoasSearchMapE222total


  const totalQuadrante1E =

    pessoasSearchMapE101total +
    pessoasSearchMapE102total +
    pessoasSearchMapE103total +
    pessoasSearchMapE104total +
    pessoasSearchMapE105total +
    pessoasSearchMapE106total +
    pessoasSearchMapE107total +
    pessoasSearchMapE108total +
    pessoasSearchMapE109total +
    pessoasSearchMapE110total +
    pessoasSearchMapE111total +
    pessoasSearchMapE112total

  const totalQuadrante2E =
    pessoasSearchMapE113total +
    pessoasSearchMapE114total +
    pessoasSearchMapE115total +
    pessoasSearchMapE116total +
    pessoasSearchMapE117total +
    pessoasSearchMapE118total +
    pessoasSearchMapE119total +
    pessoasSearchMapE120total +
    pessoasSearchMapE121total +
    pessoasSearchMapE122total

  const totalQuadrante3E =
    pessoasSearchMapE201total +
    pessoasSearchMapE202total +
    pessoasSearchMapE203total +
    pessoasSearchMapE204total +
    pessoasSearchMapE205total +
    pessoasSearchMapE206total +
    pessoasSearchMapE207total +
    pessoasSearchMapE208total +
    pessoasSearchMapE209total +
    pessoasSearchMapE210total +
    pessoasSearchMapE211total +
    pessoasSearchMapE212total

  const totalQuadrante4E =
    pessoasSearchMapE213total +
    pessoasSearchMapE214total +
    pessoasSearchMapE215total +
    pessoasSearchMapE216total +
    pessoasSearchMapE217total +
    pessoasSearchMapE218total +
    pessoasSearchMapE219total +
    pessoasSearchMapE220total +
    pessoasSearchMapE221total +
    pessoasSearchMapE222total

  const totalSomaF =
    pessoasSearchMapF101total +
    pessoasSearchMapF102total +
    pessoasSearchMapF103total +
    pessoasSearchMapF104total +
    pessoasSearchMapF105total +
    pessoasSearchMapF106total +
    pessoasSearchMapF107total +
    pessoasSearchMapF108total +
    pessoasSearchMapF109total +
    pessoasSearchMapF110total +
    pessoasSearchMapF111total +
    pessoasSearchMapF112total +
    pessoasSearchMapF113total +
    pessoasSearchMapF114total +
    pessoasSearchMapF115total +
    pessoasSearchMapF116total +
    pessoasSearchMapF117total +
    pessoasSearchMapF118total +
    pessoasSearchMapF119total +
    pessoasSearchMapF120total +
    pessoasSearchMapF121total +
    pessoasSearchMapF122total +
    pessoasSearchMapF201total +
    pessoasSearchMapF202total +
    pessoasSearchMapF203total +
    pessoasSearchMapF204total +
    pessoasSearchMapF205total +
    pessoasSearchMapF206total +
    pessoasSearchMapF207total +
    pessoasSearchMapF208total +
    pessoasSearchMapF209total +
    pessoasSearchMapF210total +
    pessoasSearchMapF211total +
    pessoasSearchMapF212total +
    pessoasSearchMapF213total +
    pessoasSearchMapF214total +
    pessoasSearchMapF215total +
    pessoasSearchMapF216total +
    pessoasSearchMapF217total +
    pessoasSearchMapF218total +
    pessoasSearchMapF219total +
    pessoasSearchMapF220total +
    pessoasSearchMapF221total +
    pessoasSearchMapF222total


  const totalQuadrante1F =

    pessoasSearchMapF101total +
    pessoasSearchMapF102total +
    pessoasSearchMapF103total +
    pessoasSearchMapF104total +
    pessoasSearchMapF105total +
    pessoasSearchMapF106total +
    pessoasSearchMapF107total +
    pessoasSearchMapF108total +
    pessoasSearchMapF109total +
    pessoasSearchMapF110total +
    pessoasSearchMapF111total +
    pessoasSearchMapF112total +
    pessoasSearchMapF113total +
    pessoasSearchMapF114total 

  const totalQuadrant2F =
    
    pessoasSearchMapF115total +
    pessoasSearchMapF116total +
    pessoasSearchMapF117total +
    pessoasSearchMapF118total +
    pessoasSearchMapF119total +
    pessoasSearchMapF120total +
    pessoasSearchMapF121total +
    pessoasSearchMapF122total

  const totalQuadrante3F =
    pessoasSearchMapF201total +
    pessoasSearchMapF202total +
    pessoasSearchMapF203total +
    pessoasSearchMapF204total +
    pessoasSearchMapF205total +
    pessoasSearchMapF206total +
    pessoasSearchMapF207total +
    pessoasSearchMapF208total +
    pessoasSearchMapF209total +
    pessoasSearchMapF210total +
    pessoasSearchMapF211total +
    pessoasSearchMapF212total +
    pessoasSearchMapF213total +
    pessoasSearchMapF214total 

  const totalQuadrante4F =
    
    pessoasSearchMapF215total +
    pessoasSearchMapF216total +
    pessoasSearchMapF217total +
    pessoasSearchMapF218total +
    pessoasSearchMapF219total +
    pessoasSearchMapF220total +
    pessoasSearchMapF221total +
    pessoasSearchMapF222total

  const totalSomaG =
    pessoasSearchMapG101total +
    pessoasSearchMapG102total +
    pessoasSearchMapG103total +
    pessoasSearchMapG104total +
    pessoasSearchMapG105total +
    pessoasSearchMapG106total +
    pessoasSearchMapG107total +
    pessoasSearchMapG108total +
    pessoasSearchMapG109total +
    pessoasSearchMapG110total +
    pessoasSearchMapG111total +
    pessoasSearchMapG112total +
    pessoasSearchMapG113total +
    pessoasSearchMapG114total +
    pessoasSearchMapG115total +
    pessoasSearchMapG116total +
    pessoasSearchMapG117total +
    pessoasSearchMapG118total +
    pessoasSearchMapG119total +
    pessoasSearchMapG120total +
    pessoasSearchMapG121total +
    pessoasSearchMapG122total +
    pessoasSearchMapG201total +
    pessoasSearchMapG202total +
    pessoasSearchMapG203total +
    pessoasSearchMapG204total +
    pessoasSearchMapG205total +
    pessoasSearchMapG206total +
    pessoasSearchMapG207total +
    pessoasSearchMapG208total +
    pessoasSearchMapG209total +
    pessoasSearchMapG210total +
    pessoasSearchMapG211total +
    pessoasSearchMapG212total +
    pessoasSearchMapG213total +
    pessoasSearchMapG214total +
    pessoasSearchMapG215total +
    pessoasSearchMapG216total +
    pessoasSearchMapG217total +
    pessoasSearchMapG218total +
    pessoasSearchMapG219total +
    pessoasSearchMapG220total +
    pessoasSearchMapG221total +
    pessoasSearchMapG222total


  const totalQuadrante1G =

    pessoasSearchMapG101total +
    pessoasSearchMapG102total +
    pessoasSearchMapG103total +
    pessoasSearchMapG104total +
    pessoasSearchMapG105total +
    pessoasSearchMapG106total +
    pessoasSearchMapG107total +
    pessoasSearchMapG108total +
    pessoasSearchMapG109total +
    pessoasSearchMapG110total +
    pessoasSearchMapG111total +
    pessoasSearchMapG112total

  const totalQuadrant2G =
    pessoasSearchMapG113total +
    pessoasSearchMapG114total +
    pessoasSearchMapG115total +
    pessoasSearchMapG116total +
    pessoasSearchMapG117total +
    pessoasSearchMapG118total +
    pessoasSearchMapG119total +
    pessoasSearchMapG120total +
    pessoasSearchMapG121total +
    pessoasSearchMapG122total

  const totalQuadrante3G =
    pessoasSearchMapG201total +
    pessoasSearchMapG202total +
    pessoasSearchMapG203total +
    pessoasSearchMapG204total +
    pessoasSearchMapG205total +
    pessoasSearchMapG206total +
    pessoasSearchMapG207total +
    pessoasSearchMapG208total +
    pessoasSearchMapG209total +
    pessoasSearchMapG210total +
    pessoasSearchMapG211total +
    pessoasSearchMapG212total

  const totalQuadrante4G =
    pessoasSearchMapG213total +
    pessoasSearchMapG214total +
    pessoasSearchMapG215total +
    pessoasSearchMapG216total +
    pessoasSearchMapG217total +
    pessoasSearchMapG218total +
    pessoasSearchMapG219total +
    pessoasSearchMapG220total +
    pessoasSearchMapG221total +
    pessoasSearchMapG222total


  const totalSomaTR =
    pessoasSearchMapTR201total +
    pessoasSearchMapTR202total


  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaE +
    totalSomaF +
    totalSomaG +
    totalSomaTR

  const totalGeral = totalUp + pessoasDomiciliartotal + pessoasHospitaltotal + pessoasPernoitetotal





  const paginaCapa = () => {

    setAtivaBotao(6)
    setPagina(6)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(1)
    setPagina(1)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGaleriaE = () => {

    setAtivaBotao(7)
    setPagina(7)

  }
  const paginaGaleriaF = () => {

    setAtivaBotao(8)
    setPagina(8)

  }
  const paginaGaleriaG = () => {

    setAtivaBotao(9)
    setPagina(9)

  }
  const paginaGeral = () => {

    setAtivaBotao(5)
    setPagina(5)

  }

 // A101
 useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA101 = "A101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA101(filteredResults);
    setPessoasSearchMapA101total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA101).toUpperCase())
}, [galerias])

// A102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA102 = "A102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA102(filteredResults);
    setPessoasSearchMapA102total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA102).toUpperCase())
}, [galerias])

// A103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA103 = "A103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA103(filteredResults);
    setPessoasSearchMapA103total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA103).toUpperCase())
}, [galerias])

// A104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA104 = "A104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA104(filteredResults);
    setPessoasSearchMapA104total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA104).toUpperCase())
}, [galerias])

// A105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA105 = "A105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA105(filteredResults);
    setPessoasSearchMapA105total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA105).toUpperCase())
}, [galerias])

// A106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA106 = "A106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA106(filteredResults);
    setPessoasSearchMapA106total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA106).toUpperCase())
}, [galerias])

// A107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA107 = "A107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA107(filteredResults);
    setPessoasSearchMapA107total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA107).toUpperCase())
}, [galerias])


// A108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA108 = "A108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA108(filteredResults);
    setPessoasSearchMapA108total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA108).toUpperCase())
}, [galerias])
// A109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA109 = "A109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA109(filteredResults);
    setPessoasSearchMapA109total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA109).toUpperCase())
}, [galerias])

// A110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA110 = "A110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA110(filteredResults);
    setPessoasSearchMapA110total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA110).toUpperCase())
}, [galerias])
// A111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA111 = "A111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA111(filteredResults);
    setPessoasSearchMapA111total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA111).toUpperCase())
}, [galerias])

// A112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA112 = "A112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA112(filteredResults);
    setPessoasSearchMapA112total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA112).toUpperCase())
}, [galerias])


// A113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA113 = "A113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA113(filteredResults);
    setPessoasSearchMapA113total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA113).toUpperCase())
}, [galerias])

// A114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA114 = "A114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA114(filteredResults);
    setPessoasSearchMapA114total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA114).toUpperCase())
}, [galerias])

// A115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA115 = "A115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA115(filteredResults);
    setPessoasSearchMapA115total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA115).toUpperCase())
}, [galerias])

// A116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA116 = "A116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA116(filteredResults);
    setPessoasSearchMapA116total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA116).toUpperCase())
}, [galerias])


// A117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA117 = "A117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA117(filteredResults);
    setPessoasSearchMapA117total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA117).toUpperCase())
}, [galerias])

// A118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA118 = "A118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA118(filteredResults);
    setPessoasSearchMapA118total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA118).toUpperCase())
}, [galerias])

// A119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA119 = "A119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA119(filteredResults);
    setPessoasSearchMapA119total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA119).toUpperCase())
}, [galerias])

// A120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA120 = "A120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA120(filteredResults);
    setPessoasSearchMapA120total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA120).toUpperCase())
}, [galerias])

// A121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA121 = "A121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA121(filteredResults);
    setPessoasSearchMapA121total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA121).toUpperCase())
}, [galerias])

// A122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA122 = "A122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA122(filteredResults);
    setPessoasSearchMapA122total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// A201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA201 = "A201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA201(filteredResults);
    setPessoasSearchMapA201total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA201).toUpperCase())
}, [galerias])

// A202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA202 = "A202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA202(filteredResults);
    setPessoasSearchMapA202total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA202).toUpperCase())
}, [galerias])

// A203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA203 = "A203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA203(filteredResults);
    setPessoasSearchMapA203total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA203).toUpperCase())
}, [galerias])

// A204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA204 = "A204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA204(filteredResults);
    setPessoasSearchMapA204total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA204).toUpperCase())
}, [galerias])
// A205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA205 = "A205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA205(filteredResults);
    setPessoasSearchMapA205total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA205).toUpperCase())
}, [galerias])

// A206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA206 = "A206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA206(filteredResults);
    setPessoasSearchMapA206total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA206).toUpperCase())
}, [galerias])

// A207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA207 = "A207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA207(filteredResults);
    setPessoasSearchMapA207total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA207).toUpperCase())
}, [galerias])


// A208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA208 = "A208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA208(filteredResults);
    setPessoasSearchMapA208total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA208).toUpperCase())
}, [galerias])
// A209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA209 = "A209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA209(filteredResults);
    setPessoasSearchMapA209total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA209).toUpperCase())
}, [galerias])

// A210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA210 = "A210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA210(filteredResults);
    setPessoasSearchMapA210total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA210).toUpperCase())
}, [galerias])
// A211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA211 = "A211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA211(filteredResults);
    setPessoasSearchMapA211total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA211).toUpperCase())
}, [galerias])

// A212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA212 = "A212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA212(filteredResults);
    setPessoasSearchMapA212total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA212).toUpperCase())
}, [galerias])

// A213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA213 = "A213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA213(filteredResults);
    setPessoasSearchMapA213total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA213).toUpperCase())
}, [galerias])

// A214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA214 = "A214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA214(filteredResults);
    setPessoasSearchMapA214total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA214).toUpperCase())
}, [galerias])

// A215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA215 = "A215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA215(filteredResults);
    setPessoasSearchMapA215total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA215).toUpperCase())
}, [galerias])

// A216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA216 = "A216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA216(filteredResults);
    setPessoasSearchMapA216total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA216).toUpperCase())
}, [galerias])


// A217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA217 = "A217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA217(filteredResults);
    setPessoasSearchMapA217total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA217).toUpperCase())
}, [galerias])

// A218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA218 = "A218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA218(filteredResults);
    setPessoasSearchMapA218total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA218).toUpperCase())
}, [galerias])

// A219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA219 = "A219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA219(filteredResults);
    setPessoasSearchMapA219total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA219).toUpperCase())
}, [galerias])

// A220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA220 = "A220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA220(filteredResults);
    setPessoasSearchMapA220total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA220).toUpperCase())
}, [galerias])

// A221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA221 = "A221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA221(filteredResults);
    setPessoasSearchMapA221total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA221).toUpperCase())
}, [galerias])

// A222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA222 = "A222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA222(filteredResults);
    setPessoasSearchMapA222total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA222).toUpperCase())
}, [galerias])

// B101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB101 = "B101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB101(filteredResults);
    setPessoasSearchMapB101total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB101).toUpperCase())
}, [galerias])

// B102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB102 = "B102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB102(filteredResults);
    setPessoasSearchMapB102total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB102).toUpperCase())
}, [galerias])

// B103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB103 = "B103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB103(filteredResults);
    setPessoasSearchMapB103total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB103).toUpperCase())
}, [galerias])

// B104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB104 = "B104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB104(filteredResults);
    setPessoasSearchMapB104total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB104).toUpperCase())
}, [galerias])
// B105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB105 = "B105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB105(filteredResults);
    setPessoasSearchMapB105total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB105).toUpperCase())
}, [galerias])

// B106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB106 = "B106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB106(filteredResults);
    setPessoasSearchMapB106total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB106).toUpperCase())
}, [galerias])

// B107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB107 = "B107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB107(filteredResults);
    setPessoasSearchMapB107total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB107).toUpperCase())
}, [galerias])


// B108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB108 = "B108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB108(filteredResults);
    setPessoasSearchMapB108total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB108).toUpperCase())
}, [galerias])
// B109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB109 = "B109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB109(filteredResults);
    setPessoasSearchMapB109total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB109).toUpperCase())
}, [galerias])

// B110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB110 = "B110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB110(filteredResults);
    setPessoasSearchMapB110total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB110).toUpperCase())
}, [galerias])
// B111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB111 = "B111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB111(filteredResults);
    setPessoasSearchMapB111total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB111).toUpperCase())
}, [galerias])

// B112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB112 = "B112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB112(filteredResults);
    setPessoasSearchMapB112total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB112).toUpperCase())
}, [galerias])


// B113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB113 = "B113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB113(filteredResults);
    setPessoasSearchMapB113total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB113).toUpperCase())
}, [galerias])

// B114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB114 = "B114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB114(filteredResults);
    setPessoasSearchMapB114total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB114).toUpperCase())
}, [galerias])

// B115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB115 = "B115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB115(filteredResults);
    setPessoasSearchMapB115total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB115).toUpperCase())
}, [galerias])

// B116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB116 = "B116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB116(filteredResults);
    setPessoasSearchMapB116total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB116).toUpperCase())
}, [galerias])


// B117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB117 = "B117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB117(filteredResults);
    setPessoasSearchMapB117total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB117).toUpperCase())
}, [galerias])

// B118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB118 = "B118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB118(filteredResults);
    setPessoasSearchMapB118total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB118).toUpperCase())
}, [galerias])

// B119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB119 = "B119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB119(filteredResults);
    setPessoasSearchMapB119total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB119).toUpperCase())
}, [galerias])

// B120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB120 = "B120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB120(filteredResults);
    setPessoasSearchMapB120total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB120).toUpperCase())
}, [galerias])

// B121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB121 = "B121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB121(filteredResults);
    setPessoasSearchMapB121total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB121).toUpperCase())
}, [galerias])

// B122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB122 = "B122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB122(filteredResults);
    setPessoasSearchMapB122total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// B201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB201 = "B201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB201(filteredResults);
    setPessoasSearchMapB201total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB201).toUpperCase())
}, [galerias])

// B202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB202 = "B202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB202(filteredResults);
    setPessoasSearchMapB202total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB202).toUpperCase())
}, [galerias])

// B203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB203 = "B203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB203(filteredResults);
    setPessoasSearchMapB203total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB203).toUpperCase())
}, [galerias])

// B204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB204 = "B204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB204(filteredResults);
    setPessoasSearchMapB204total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB204).toUpperCase())
}, [galerias])
// B205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB205 = "B205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB205(filteredResults);
    setPessoasSearchMapB205total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB205).toUpperCase())
}, [galerias])

// B206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB206 = "B206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB206(filteredResults);
    setPessoasSearchMapB206total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB206).toUpperCase())
}, [galerias])

// B207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB207 = "B207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB207(filteredResults);
    setPessoasSearchMapB207total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB207).toUpperCase())
}, [galerias])


// B208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB208 = "B208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB208(filteredResults);
    setPessoasSearchMapB208total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB208).toUpperCase())
}, [galerias])
// B209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB209 = "B209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB209(filteredResults);
    setPessoasSearchMapB209total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB209).toUpperCase())
}, [galerias])

// B210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB210 = "B210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB210(filteredResults);
    setPessoasSearchMapB210total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB210).toUpperCase())
}, [galerias])
// B211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB211 = "B211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB211(filteredResults);
    setPessoasSearchMapB211total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB211).toUpperCase())
}, [galerias])

// B212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB212 = "B212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB212(filteredResults);
    setPessoasSearchMapB212total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB212).toUpperCase())
}, [galerias])

// B213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB213 = "B213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB213(filteredResults);
    setPessoasSearchMapB213total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB213).toUpperCase())
}, [galerias])

// B214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB214 = "B214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB214(filteredResults);
    setPessoasSearchMapB214total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB214).toUpperCase())
}, [galerias])

// B215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB215 = "B215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB215(filteredResults);
    setPessoasSearchMapB215total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB215).toUpperCase())
}, [galerias])

// B216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB216 = "B216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB216(filteredResults);
    setPessoasSearchMapB216total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB216).toUpperCase())
}, [galerias])


// B217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB217 = "B217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB217(filteredResults);
    setPessoasSearchMapB217total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB217).toUpperCase())
}, [galerias])

// B218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB218 = "B218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB218(filteredResults);
    setPessoasSearchMapB218total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB218).toUpperCase())
}, [galerias])

// B219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB219 = "B219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB219(filteredResults);
    setPessoasSearchMapB219total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB219).toUpperCase())
}, [galerias])

// B220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB220 = "B220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB220(filteredResults);
    setPessoasSearchMapB220total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB220).toUpperCase())
}, [galerias])

// B221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB221 = "B221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB221(filteredResults);
    setPessoasSearchMapB221total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB221).toUpperCase())
}, [galerias])

// B222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB222 = "B222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB222(filteredResults);
    setPessoasSearchMapB222total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB222).toUpperCase())
}, [galerias])

//C C101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC101 = "C101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC101(filteredResults);
    setPessoasSearchMapC101total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC101).toUpperCase())
}, [galerias])

// C102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC102 = "C102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC102(filteredResults);
    setPessoasSearchMapC102total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC102).toUpperCase())
}, [galerias])

// C103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC103 = "C103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC103(filteredResults);
    setPessoasSearchMapC103total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC103).toUpperCase())
}, [galerias])

// C104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC104 = "C104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC104(filteredResults);
    setPessoasSearchMapC104total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC104).toUpperCase())
}, [galerias])
// C105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC105 = "C105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC105(filteredResults);
    setPessoasSearchMapC105total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC105).toUpperCase())
}, [galerias])

// C106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC106 = "C106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC106(filteredResults);
    setPessoasSearchMapC106total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC106).toUpperCase())
}, [galerias])

// C107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC107 = "C107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC107(filteredResults);
    setPessoasSearchMapC107total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC107).toUpperCase())
}, [galerias])


// C108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC108 = "C108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC108(filteredResults);
    setPessoasSearchMapC108total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC108).toUpperCase())
}, [galerias])
// C109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC109 = "C109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC109(filteredResults);
    setPessoasSearchMapC109total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC109).toUpperCase())
}, [galerias])

// C110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC110 = "C110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC110(filteredResults);
    setPessoasSearchMapC110total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC110).toUpperCase())
}, [galerias])
// C111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC111 = "C111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC111(filteredResults);
    setPessoasSearchMapC111total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC111).toUpperCase())
}, [galerias])

// C112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC112 = "C112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC112(filteredResults);
    setPessoasSearchMapC112total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC112).toUpperCase())
}, [galerias])


// C113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC113 = "C113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC113(filteredResults);
    setPessoasSearchMapC113total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC113).toUpperCase())
}, [galerias])

// C114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC114 = "C114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC114(filteredResults);
    setPessoasSearchMapC114total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC114).toUpperCase())
}, [galerias])

// C115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC115 = "C115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC115(filteredResults);
    setPessoasSearchMapC115total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC115).toUpperCase())
}, [galerias])

// C116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC116 = "C116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC116(filteredResults);
    setPessoasSearchMapC116total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC116).toUpperCase())
}, [galerias])


// C117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC117 = "C117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC117(filteredResults);
    setPessoasSearchMapC117total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC117).toUpperCase())
}, [galerias])

// C118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC118 = "C118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC118(filteredResults);
    setPessoasSearchMapC118total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC118).toUpperCase())
}, [galerias])

// C119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC119 = "C119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC119(filteredResults);
    setPessoasSearchMapC119total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC119).toUpperCase())
}, [galerias])

// C120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC120 = "C120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC120(filteredResults);
    setPessoasSearchMapC120total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC120).toUpperCase())
}, [galerias])

// C121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC121 = "C121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC121(filteredResults);
    setPessoasSearchMapC121total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC121).toUpperCase())
}, [galerias])

// C122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC122 = "C122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC122(filteredResults);
    setPessoasSearchMapC122total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// C201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC201 = "C201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC201(filteredResults);
    setPessoasSearchMapC201total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC201).toUpperCase())
}, [galerias])

// C202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC202 = "C202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC202(filteredResults);
    setPessoasSearchMapC202total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC202).toUpperCase())
}, [galerias])

// C203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC203 = "C203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC203(filteredResults);
    setPessoasSearchMapC203total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC203).toUpperCase())
}, [galerias])

// C204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC204 = "C204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC204(filteredResults);
    setPessoasSearchMapC204total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC204).toUpperCase())
}, [galerias])
// C205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC205 = "C205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC205(filteredResults);
    setPessoasSearchMapC205total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC205).toUpperCase())
}, [galerias])

// C206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC206 = "C206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC206(filteredResults);
    setPessoasSearchMapC206total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC206).toUpperCase())
}, [galerias])

// C207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC207 = "C207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC207(filteredResults);
    setPessoasSearchMapC207total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC207).toUpperCase())
}, [galerias])


// C208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC208 = "C208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC208(filteredResults);
    setPessoasSearchMapC208total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC208).toUpperCase())
}, [galerias])
// C209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC209 = "C209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC209(filteredResults);
    setPessoasSearchMapC209total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC209).toUpperCase())
}, [galerias])

// C210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC210 = "C210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC210(filteredResults);
    setPessoasSearchMapC210total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC210).toUpperCase())
}, [galerias])
// C211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC211 = "C211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC211(filteredResults);
    setPessoasSearchMapC211total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC211).toUpperCase())
}, [galerias])

// C212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC212 = "C212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC212(filteredResults);
    setPessoasSearchMapC212total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC212).toUpperCase())
}, [galerias])

// C213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC213 = "C213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC213(filteredResults);
    setPessoasSearchMapC213total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC213).toUpperCase())
}, [galerias])

// C214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC214 = "C214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC214(filteredResults);
    setPessoasSearchMapC214total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC214).toUpperCase())
}, [galerias])

// C215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC215 = "C215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC215(filteredResults);
    setPessoasSearchMapC215total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC215).toUpperCase())
}, [galerias])

// C216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC216 = "C216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC216(filteredResults);
    setPessoasSearchMapC216total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC216).toUpperCase())
}, [galerias])


// C217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC217 = "C217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC217(filteredResults);
    setPessoasSearchMapC217total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC217).toUpperCase())
}, [galerias])

// C218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC218 = "C218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC218(filteredResults);
    setPessoasSearchMapC218total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC218).toUpperCase())
}, [galerias])

// C219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC219 = "C219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC219(filteredResults);
    setPessoasSearchMapC219total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC219).toUpperCase())
}, [galerias])

// C220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC220 = "C220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC220(filteredResults);
    setPessoasSearchMapC220total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC220).toUpperCase())
}, [galerias])

// C221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC221 = "C221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC221(filteredResults);
    setPessoasSearchMapC221total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC221).toUpperCase())
}, [galerias])

// C222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC222 = "C222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC222(filteredResults);
    setPessoasSearchMapC222total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC222).toUpperCase())
}, [galerias])

//C D101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD101 = "D101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD101(filteredResults);
    setPessoasSearchMapD101total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD101).toUpperCase())
}, [galerias])

// D102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD102 = "D102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD102(filteredResults);
    setPessoasSearchMapD102total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD102).toUpperCase())
}, [galerias])

// D103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD103 = "D103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD103(filteredResults);
    setPessoasSearchMapD103total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD103).toUpperCase())
}, [galerias])

// D104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD104 = "D104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD104(filteredResults);
    setPessoasSearchMapD104total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD104).toUpperCase())
}, [galerias])
// D105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD105 = "D105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD105(filteredResults);
    setPessoasSearchMapD105total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD105).toUpperCase())
}, [galerias])

// D106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD106 = "D106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD106(filteredResults);
    setPessoasSearchMapD106total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD106).toUpperCase())
}, [galerias])

// D107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD107 = "D107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD107(filteredResults);
    setPessoasSearchMapD107total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD107).toUpperCase())
}, [galerias])


// D108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD108 = "D108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD108(filteredResults);
    setPessoasSearchMapD108total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD108).toUpperCase())
}, [galerias])
// D109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD109 = "D109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD109(filteredResults);
    setPessoasSearchMapD109total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD109).toUpperCase())
}, [galerias])

// D110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD110 = "D110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD110(filteredResults);
    setPessoasSearchMapD110total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD110).toUpperCase())
}, [galerias])
// D111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD111 = "D111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD111(filteredResults);
    setPessoasSearchMapD111total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD111).toUpperCase())
}, [galerias])

// D112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD112 = "D112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD112(filteredResults);
    setPessoasSearchMapD112total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD112).toUpperCase())
}, [galerias])


// D113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD113 = "D113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD113(filteredResults);
    setPessoasSearchMapD113total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD113).toUpperCase())
}, [galerias])

// D114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD114 = "D114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD114(filteredResults);
    setPessoasSearchMapD114total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD114).toUpperCase())
}, [galerias])

// D115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD115 = "D115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD115(filteredResults);
    setPessoasSearchMapD115total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD115).toUpperCase())
}, [galerias])

// D116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD116 = "D116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD116(filteredResults);
    setPessoasSearchMapD116total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD116).toUpperCase())
}, [galerias])


// D117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD117 = "D117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD117(filteredResults);
    setPessoasSearchMapD117total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD117).toUpperCase())
}, [galerias])

// D118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD118 = "D118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD118(filteredResults);
    setPessoasSearchMapD118total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD118).toUpperCase())
}, [galerias])

// D119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD119 = "D119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD119(filteredResults);
    setPessoasSearchMapD119total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD119).toUpperCase())
}, [galerias])

// D120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD120 = "D120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD120(filteredResults);
    setPessoasSearchMapD120total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD120).toUpperCase())
}, [galerias])

// C121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD121 = "D121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD121(filteredResults);
    setPessoasSearchMapD121total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD121).toUpperCase())
}, [galerias])

// C122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD122 = "D122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD122(filteredResults);
    setPessoasSearchMapD122total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// D201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD201 = "D201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD201(filteredResults);
    setPessoasSearchMapD201total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD201).toUpperCase())
}, [galerias])

// D202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD202 = "D202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD202(filteredResults);
    setPessoasSearchMapD202total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD202).toUpperCase())
}, [galerias])

// D203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD203 = "D203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD203(filteredResults);
    setPessoasSearchMapD203total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD203).toUpperCase())
}, [galerias])

// D204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD204 = "D204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD204(filteredResults);
    setPessoasSearchMapD204total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD204).toUpperCase())
}, [galerias])
// D205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD205 = "D205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD205(filteredResults);
    setPessoasSearchMapD205total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD205).toUpperCase())
}, [galerias])

// D206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD206 = "D206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD206(filteredResults);
    setPessoasSearchMapD206total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD206).toUpperCase())
}, [galerias])

// D207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD207 = "D207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD207(filteredResults);
    setPessoasSearchMapD207total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD207).toUpperCase())
}, [galerias])


// D208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD208 = "D208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD208(filteredResults);
    setPessoasSearchMapD208total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD208).toUpperCase())
}, [galerias])
// D209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD209 = "D209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD209(filteredResults);
    setPessoasSearchMapD209total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD209).toUpperCase())
}, [galerias])

// D210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD210 = "D210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD210(filteredResults);
    setPessoasSearchMapD210total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD210).toUpperCase())
}, [galerias])
// D211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD211 = "D211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD211(filteredResults);
    setPessoasSearchMapD211total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD211).toUpperCase())
}, [galerias])

// D212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD212 = "D212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD212(filteredResults);
    setPessoasSearchMapD212total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD212).toUpperCase())
}, [galerias])

// D213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD213 = "D213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD213(filteredResults);
    setPessoasSearchMapD213total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD213).toUpperCase())
}, [galerias])

// D214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD214 = "D214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD214(filteredResults);
    setPessoasSearchMapD214total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD214).toUpperCase())
}, [galerias])

// D215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD215 = "D215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD215(filteredResults);
    setPessoasSearchMapD215total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD215).toUpperCase())
}, [galerias])

// D216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD216 = "D216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD216(filteredResults);
    setPessoasSearchMapD216total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD216).toUpperCase())
}, [galerias])


// D217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD217 = "D217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD217(filteredResults);
    setPessoasSearchMapD217total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD217).toUpperCase())
}, [galerias])

// D218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD218 = "D218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD218(filteredResults);
    setPessoasSearchMapD218total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD218).toUpperCase())
}, [galerias])

// D219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD219 = "D219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD219(filteredResults);
    setPessoasSearchMapD219total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD219).toUpperCase())
}, [galerias])

// D220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD220 = "D220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD220(filteredResults);
    setPessoasSearchMapD220total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD220).toUpperCase())
}, [galerias])

// D221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD221 = "D221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD221(filteredResults);
    setPessoasSearchMapD221total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD221).toUpperCase())
}, [galerias])

// D222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaD222 = "D222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapD222(filteredResults);
    setPessoasSearchMapD222total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaD222).toUpperCase())
}, [galerias])


//C E101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE101 = "E101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE101(filteredResults);
    setPessoasSearchMapE101total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE101).toUpperCase())
}, [galerias])

// E102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE102 = "E102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE102(filteredResults);
    setPessoasSearchMapE102total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE102).toUpperCase())
}, [galerias])

// E103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE103 = "E103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE103(filteredResults);
    setPessoasSearchMapE103total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE103).toUpperCase())
}, [galerias])

// E104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE104 = "E104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE104(filteredResults);
    setPessoasSearchMapE104total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE104).toUpperCase())
}, [galerias])
// E105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE105 = "E105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE105(filteredResults);
    setPessoasSearchMapE105total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE105).toUpperCase())
}, [galerias])

// E106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE106 = "E106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE106(filteredResults);
    setPessoasSearchMapE106total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE106).toUpperCase())
}, [galerias])

// E107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE107 = "E107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE107(filteredResults);
    setPessoasSearchMapE107total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE107).toUpperCase())
}, [galerias])


// E108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE108 = "E108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE108(filteredResults);
    setPessoasSearchMapE108total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE108).toUpperCase())
}, [galerias])
// E109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE109 = "E109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE109(filteredResults);
    setPessoasSearchMapE109total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE109).toUpperCase())
}, [galerias])

// E110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE110 = "E110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE110(filteredResults);
    setPessoasSearchMapE110total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE110).toUpperCase())
}, [galerias])
// E111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE111 = "E111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE111(filteredResults);
    setPessoasSearchMapE111total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE111).toUpperCase())
}, [galerias])

// E112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE112 = "E112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE112(filteredResults);
    setPessoasSearchMapE112total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE112).toUpperCase())
}, [galerias])


// E113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE113 = "E113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE113(filteredResults);
    setPessoasSearchMapE113total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE113).toUpperCase())
}, [galerias])

// E114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE114 = "E114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE114(filteredResults);
    setPessoasSearchMapE114total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE114).toUpperCase())
}, [galerias])

// E115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE115 = "E115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE115(filteredResults);
    setPessoasSearchMapE115total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE115).toUpperCase())
}, [galerias])

// E116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE116 = "E116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE116(filteredResults);
    setPessoasSearchMapE116total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE116).toUpperCase())
}, [galerias])


// E117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE117 = "E117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE117(filteredResults);
    setPessoasSearchMapE117total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE117).toUpperCase())
}, [galerias])

// E118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE118 = "E118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE118(filteredResults);
    setPessoasSearchMapE118total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE118).toUpperCase())
}, [galerias])

// E119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE119 = "E119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE119(filteredResults);
    setPessoasSearchMapE119total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE119).toUpperCase())
}, [galerias])

// E120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE120 = "E120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE120(filteredResults);
    setPessoasSearchMapE120total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE120).toUpperCase())
}, [galerias])

// C121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE121 = "E121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE121(filteredResults);
    setPessoasSearchMapE121total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE121).toUpperCase())
}, [galerias])

// C122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE122 = "E122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE122(filteredResults);
    setPessoasSearchMapE122total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// E201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE201 = "E201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE201(filteredResults);
    setPessoasSearchMapE201total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE201).toUpperCase())
}, [galerias])

// E202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE202 = "E202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE202(filteredResults);
    setPessoasSearchMapE202total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE202).toUpperCase())
}, [galerias])

// E203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE203 = "E203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE203(filteredResults);
    setPessoasSearchMapE203total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE203).toUpperCase())
}, [galerias])

// E204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE204 = "E204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE204(filteredResults);
    setPessoasSearchMapE204total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE204).toUpperCase())
}, [galerias])
// E205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE205 = "E205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE205(filteredResults);
    setPessoasSearchMapE205total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE205).toUpperCase())
}, [galerias])

// E206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE206 = "E206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE206(filteredResults);
    setPessoasSearchMapE206total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE206).toUpperCase())
}, [galerias])

// E207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE207 = "E207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE207(filteredResults);
    setPessoasSearchMapE207total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE207).toUpperCase())
}, [galerias])


// E208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE208 = "E208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE208(filteredResults);
    setPessoasSearchMapE208total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE208).toUpperCase())
}, [galerias])
// E209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE209 = "E209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE209(filteredResults);
    setPessoasSearchMapE209total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE209).toUpperCase())
}, [galerias])

// E210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE210 = "E210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE210(filteredResults);
    setPessoasSearchMapE210total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE210).toUpperCase())
}, [galerias])
// E211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE211 = "E211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE211(filteredResults);
    setPessoasSearchMapE211total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE211).toUpperCase())
}, [galerias])

// E212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE212 = "E212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE212(filteredResults);
    setPessoasSearchMapE212total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE212).toUpperCase())
}, [galerias])

// E213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE213 = "E213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE213(filteredResults);
    setPessoasSearchMapE213total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE213).toUpperCase())
}, [galerias])

// E214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE214 = "E214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE214(filteredResults);
    setPessoasSearchMapE214total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE214).toUpperCase())
}, [galerias])

// E215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE215 = "E215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE215(filteredResults);
    setPessoasSearchMapE215total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE215).toUpperCase())
}, [galerias])

// E216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE216 = "E216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE216(filteredResults);
    setPessoasSearchMapE216total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE216).toUpperCase())
}, [galerias])


// E217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE217 = "E217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE217(filteredResults);
    setPessoasSearchMapE217total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE217).toUpperCase())
}, [galerias])

// E218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE218 = "E218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE218(filteredResults);
    setPessoasSearchMapE218total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE218).toUpperCase())
}, [galerias])

// E219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE219 = "E219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE219(filteredResults);
    setPessoasSearchMapE219total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE219).toUpperCase())
}, [galerias])

// E220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE220 = "E220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE220(filteredResults);
    setPessoasSearchMapE220total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE220).toUpperCase())
}, [galerias])

// E221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE221 = "E221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE221(filteredResults);
    setPessoasSearchMapE221total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE221).toUpperCase())
}, [galerias])

// E222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE222 = "E222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE222(filteredResults);
    setPessoasSearchMapE222total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE222).toUpperCase())
}, [galerias])



//C F101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF101 = "F101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF101(filteredResults);
    setPessoasSearchMapF101total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF101).toUpperCase())
}, [galerias])

// F102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF102 = "F102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF102(filteredResults);
    setPessoasSearchMapF102total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF102).toUpperCase())
}, [galerias])

// F103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF103 = "F103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF103(filteredResults);
    setPessoasSearchMapF103total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF103).toUpperCase())
}, [galerias])

// F104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF104 = "F104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF104(filteredResults);
    setPessoasSearchMapF104total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF104).toUpperCase())
}, [galerias])
// F105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF105 = "F105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF105(filteredResults);
    setPessoasSearchMapF105total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF105).toUpperCase())
}, [galerias])

// F106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF106 = "F106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF106(filteredResults);
    setPessoasSearchMapF106total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF106).toUpperCase())
}, [galerias])

// F107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF107 = "F107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF107(filteredResults);
    setPessoasSearchMapF107total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF107).toUpperCase())
}, [galerias])


// F108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF108 = "F108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF108(filteredResults);
    setPessoasSearchMapF108total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF108).toUpperCase())
}, [galerias])
// F109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF109 = "F109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF109(filteredResults);
    setPessoasSearchMapF109total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF109).toUpperCase())
}, [galerias])

// F110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF110 = "F110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF110(filteredResults);
    setPessoasSearchMapF110total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF110).toUpperCase())
}, [galerias])
// F111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF111 = "F111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF111(filteredResults);
    setPessoasSearchMapF111total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF111).toUpperCase())
}, [galerias])

// F112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF112 = "F112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF112(filteredResults);
    setPessoasSearchMapF112total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF112).toUpperCase())
}, [galerias])


// F113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF113 = "F113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF113(filteredResults);
    setPessoasSearchMapF113total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF113).toUpperCase())
}, [galerias])

// F114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF114 = "F114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF114(filteredResults);
    setPessoasSearchMapF114total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF114).toUpperCase())
}, [galerias])

// F115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF115 = "F115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF115(filteredResults);
    setPessoasSearchMapF115total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF115).toUpperCase())
}, [galerias])

// F116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF116 = "F116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF116(filteredResults);
    setPessoasSearchMapF116total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF116).toUpperCase())
}, [galerias])


// F117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF117 = "F117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF117(filteredResults);
    setPessoasSearchMapF117total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF117).toUpperCase())
}, [galerias])

// F118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF118 = "F118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF118(filteredResults);
    setPessoasSearchMapF118total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF118).toUpperCase())
}, [galerias])

// F119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF119 = "F119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF119(filteredResults);
    setPessoasSearchMapF119total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF119).toUpperCase())
}, [galerias])

// F120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF120 = "F120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF120(filteredResults);
    setPessoasSearchMapF120total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF120).toUpperCase())
}, [galerias])

// C121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF121 = "F121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF121(filteredResults);
    setPessoasSearchMapF121total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF121).toUpperCase())
}, [galerias])

// C122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF122 = "F122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF122(filteredResults);
    setPessoasSearchMapF122total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// F201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF201 = "F201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF201(filteredResults);
    setPessoasSearchMapF201total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF201).toUpperCase())
}, [galerias])

// F202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF202 = "F202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF202(filteredResults);
    setPessoasSearchMapF202total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF202).toUpperCase())
}, [galerias])

// F203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF203 = "F203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF203(filteredResults);
    setPessoasSearchMapF203total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF203).toUpperCase())
}, [galerias])

// F204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF204 = "F204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF204(filteredResults);
    setPessoasSearchMapF204total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF204).toUpperCase())
}, [galerias])
// F205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF205 = "F205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF205(filteredResults);
    setPessoasSearchMapF205total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF205).toUpperCase())
}, [galerias])

// F206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF206 = "F206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF206(filteredResults);
    setPessoasSearchMapF206total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF206).toUpperCase())
}, [galerias])

// F207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF207 = "F207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF207(filteredResults);
    setPessoasSearchMapF207total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF207).toUpperCase())
}, [galerias])


// F208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF208 = "F208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF208(filteredResults);
    setPessoasSearchMapF208total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF208).toUpperCase())
}, [galerias])
// F209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF209 = "F209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF209(filteredResults);
    setPessoasSearchMapF209total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF209).toUpperCase())
}, [galerias])

// F210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF210 = "F210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF210(filteredResults);
    setPessoasSearchMapF210total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF210).toUpperCase())
}, [galerias])
// F211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF211 = "F211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF211(filteredResults);
    setPessoasSearchMapF211total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF211).toUpperCase())
}, [galerias])

// F212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF212 = "F212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF212(filteredResults);
    setPessoasSearchMapF212total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF212).toUpperCase())
}, [galerias])

// F213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF213 = "F213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF213(filteredResults);
    setPessoasSearchMapF213total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF213).toUpperCase())
}, [galerias])

// F214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF214 = "F214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF214(filteredResults);
    setPessoasSearchMapF214total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF214).toUpperCase())
}, [galerias])

// F215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF215 = "F215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF215(filteredResults);
    setPessoasSearchMapF215total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF215).toUpperCase())
}, [galerias])

// F216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF216 = "F216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF216(filteredResults);
    setPessoasSearchMapF216total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF216).toUpperCase())
}, [galerias])


// F217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF217 = "F217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF217(filteredResults);
    setPessoasSearchMapF217total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF217).toUpperCase())
}, [galerias])

// F218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF218 = "F218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF218(filteredResults);
    setPessoasSearchMapF218total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF218).toUpperCase())
}, [galerias])

// F219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF219 = "F219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF219(filteredResults);
    setPessoasSearchMapF219total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF219).toUpperCase())
}, [galerias])

// F220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF220 = "F220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF220(filteredResults);
    setPessoasSearchMapF220total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF220).toUpperCase())
}, [galerias])

// F221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF221 = "F221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF221(filteredResults);
    setPessoasSearchMapF221total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF221).toUpperCase())
}, [galerias])

// F222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF222 = "F222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF222(filteredResults);
    setPessoasSearchMapF222total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF222).toUpperCase())
}, [galerias])



//C G101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG101 = "G101";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG101(filteredResults);
    setPessoasSearchMapG101total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG101).toUpperCase())
}, [galerias])

// G102
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG102 = "G102";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG102(filteredResults);
    setPessoasSearchMapG102total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG102).toUpperCase())
}, [galerias])

// G103
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG103 = "G103";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG103(filteredResults);
    setPessoasSearchMapG103total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG103).toUpperCase())
}, [galerias])

// G104
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG104 = "G104";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG104(filteredResults);
    setPessoasSearchMapG104total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG104).toUpperCase())
}, [galerias])
// G105
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG105 = "G105";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG105(filteredResults);
    setPessoasSearchMapG105total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG105).toUpperCase())
}, [galerias])

// G106
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG106 = "G106";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG106(filteredResults);
    setPessoasSearchMapG106total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG106).toUpperCase())
}, [galerias])

// G107
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG107 = "G107";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG107(filteredResults);
    setPessoasSearchMapG107total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG107).toUpperCase())
}, [galerias])


// G108
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG108 = "G108";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG108(filteredResults);
    setPessoasSearchMapG108total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG108).toUpperCase())
}, [galerias])
// G109
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG109 = "G109";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG109(filteredResults);
    setPessoasSearchMapG109total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG109).toUpperCase())
}, [galerias])

// G110
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG110 = "G110";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG110(filteredResults);
    setPessoasSearchMapG110total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG110).toUpperCase())
}, [galerias])
// G111
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG111 = "G111";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG111(filteredResults);
    setPessoasSearchMapG111total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG111).toUpperCase())
}, [galerias])

// G112
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG112 = "G112";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG112(filteredResults);
    setPessoasSearchMapG112total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG112).toUpperCase())
}, [galerias])


// G113
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG113 = "G113";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG113(filteredResults);
    setPessoasSearchMapG113total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG113).toUpperCase())
}, [galerias])

// G114
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG114 = "G114";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG114(filteredResults);
    setPessoasSearchMapG114total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG114).toUpperCase())
}, [galerias])

// G115
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG115 = "G115";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG115(filteredResults);
    setPessoasSearchMapG115total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG115).toUpperCase())
}, [galerias])

// G116
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG116 = "G116";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG116(filteredResults);
    setPessoasSearchMapG116total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG116).toUpperCase())
}, [galerias])


// G117
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG117 = "G117";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG117(filteredResults);
    setPessoasSearchMapG117total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG117).toUpperCase())
}, [galerias])

// G118
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG118 = "G118";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG118(filteredResults);
    setPessoasSearchMapG118total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG118).toUpperCase())
}, [galerias])

// G119
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG119 = "G119";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG119(filteredResults);
    setPessoasSearchMapG119total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG119).toUpperCase())
}, [galerias])

// G120
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG120 = "G120";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG120(filteredResults);
    setPessoasSearchMapG120total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG120).toUpperCase())
}, [galerias])

// C121
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG121 = "G121";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG121(filteredResults);
    setPessoasSearchMapG121total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG121).toUpperCase())
}, [galerias])

// C122
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG122 = "G122";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG122(filteredResults);
    setPessoasSearchMapG122total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG122).toUpperCase())
}, [galerias])
///////////////////////////////////////////////////////////////////////


// G201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG201 = "G201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG201(filteredResults);
    setPessoasSearchMapG201total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG201).toUpperCase())
}, [galerias])

// G202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG202 = "G202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG202(filteredResults);
    setPessoasSearchMapG202total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG202).toUpperCase())
}, [galerias])

// G203
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG203 = "G203";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG203(filteredResults);
    setPessoasSearchMapG203total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG203).toUpperCase())
}, [galerias])

// G204
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG204 = "G204";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG204(filteredResults);
    setPessoasSearchMapG204total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG204).toUpperCase())
}, [galerias])
// G205
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG205 = "G205";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG205(filteredResults);
    setPessoasSearchMapG205total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG205).toUpperCase())
}, [galerias])

// G206
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG206 = "G206";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG206(filteredResults);
    setPessoasSearchMapG206total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG206).toUpperCase())
}, [galerias])

// G207
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG207 = "G207";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG207(filteredResults);
    setPessoasSearchMapG207total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG207).toUpperCase())
}, [galerias])


// G208
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG208 = "G208";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG208(filteredResults);
    setPessoasSearchMapG208total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG208).toUpperCase())
}, [galerias])
// G209
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG209 = "G209";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG209(filteredResults);
    setPessoasSearchMapG209total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG209).toUpperCase())
}, [galerias])

// G210
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG210 = "G210";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG210(filteredResults);
    setPessoasSearchMapG210total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG210).toUpperCase())
}, [galerias])
// G211
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG211 = "G211";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG211(filteredResults);
    setPessoasSearchMapG211total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG211).toUpperCase())
}, [galerias])

// G212
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG212 = "G212";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG212(filteredResults);
    setPessoasSearchMapG212total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG212).toUpperCase())
}, [galerias])

// G213
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG213 = "G213";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG213(filteredResults);
    setPessoasSearchMapG213total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG213).toUpperCase())
}, [galerias])

// G214
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG214 = "G214";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG214(filteredResults);
    setPessoasSearchMapG214total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG214).toUpperCase())
}, [galerias])

// G215
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG215 = "G215";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG215(filteredResults);
    setPessoasSearchMapG215total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG215).toUpperCase())
}, [galerias])

// G216
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG216 = "G216";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG216(filteredResults);
    setPessoasSearchMapG216total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG216).toUpperCase())
}, [galerias])


// G217
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG217 = "G217";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG217(filteredResults);
    setPessoasSearchMapG217total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG217).toUpperCase())
}, [galerias])

// G218
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG218 = "G218";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG218(filteredResults);
    setPessoasSearchMapG218total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG218).toUpperCase())
}, [galerias])

// G219
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG219 = "G219";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG219(filteredResults);
    setPessoasSearchMapG219total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG219).toUpperCase())
}, [galerias])

// G220
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG220 = "G220";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG220(filteredResults);
    setPessoasSearchMapG220total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG220).toUpperCase())
}, [galerias])

// G221
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG221 = "G221";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG221(filteredResults);
    setPessoasSearchMapG221total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG221).toUpperCase())
}, [galerias])

// G222
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG222 = "G222";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG222(filteredResults);
    setPessoasSearchMapG222total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaG222).toUpperCase())
}, [galerias])




// TR201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR201 = "TR201";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR201(filteredResults);
    setPessoasSearchMapTR201total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR201).toUpperCase())
}, [galerias])


// TR202
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR202 = "TR202";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR202(filteredResults);
    setPessoasSearchMapTR202total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR202).toUpperCase())
}, [galerias])



// DOMICILIAR
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let domiciliar = "DOMICILIAR";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasDomiciliar(filteredResults);
    setPessoasDomiciliartotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((domiciliar).toUpperCase())
}, [galerias])


// HOSPITAL
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let hospital = "HOSPITAL";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasHospital(filteredResults);
    setPessoasHospitaltotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((hospital).toUpperCase())
}, [galerias])


// HOSPITAL
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let pernoite = "PERNOITE";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasPernoite(filteredResults);
    setPessoasPernoitetotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((pernoite).toUpperCase())
}, [galerias])






  return (




    <>
      <div className={styles.listaGalerias}>




        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaCapa}>
          <b style={ativaBotao === 6 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>CAPA</b></b>
        </div>
        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaA}>
          <b style={ativaBotao === 1 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (A)</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaB}>
          <b style={ativaBotao === 2 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (B)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaC}>
          <b style={ativaBotao === 3 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (C)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaD}>
          <b style={ativaBotao === 4 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (D)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaE}>
          <b style={ativaBotao === 7 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (E)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaF}>
          <b style={ativaBotao === 8 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (F)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaG}>
          <b style={ativaBotao === 9 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (G , TR)</b></b>
        </div>
       
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGeral}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>TODAS AS GALERIAS</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer', marginLeft:10,  }} onClick={exportToExcel}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><img src={excel} alt="Logo" height={40} /></b>
        </div>

        {/* Continue com os outros elementos... */}
      </div>

      {(pagina === 6) && <div id='capa'>

        <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CAPA</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
        </Button></center></p>
        <center><div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao" style={{
          border: '1px solid black',
          width: '210mm',
          height: '300mm',
          margin: '0 auto',
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: 'center',
          justifyItems: 'center'



        }}>
          <br></br><br></br>
          <center> 

            <p><h2><img src={logo} alt="Logo" height={48} /><b> POLICIA PENAL | ES</b> </h2></p>
           <p>CENTRO DE DETENÇÃO PROVISÓRIA DE VIANA II (CDPV II) <small> - <b>{dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small></p> 
          
            
           
            
           
            
          </center>
          <Table bordered style={{ width: '60%', fontSize:14, alignItems:'center', textAlign:'center' }}>
            <thead>
              <tr>
                <th>LOCAL</th>
                <th>QUANTITATIVO</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td>GALERIA "A"</td>
                <td>{totalSomaA} </td>
              </tr>
              <tr>
                <td>GALERIA "B"</td>
                <td>{totalSomaB} </td>
              </tr>
              <tr>
                <td>GALERIA "C"</td>
                <td>{totalSomaC} </td>
              </tr>
              <tr>
                <td>GALERIA "D"</td>
                <td>{totalSomaD} </td>
              </tr>
              <tr>
                <td>GALERIA "E"</td>
                <td>{totalSomaE} </td>
              </tr>
              <tr>
                <td>GALERIA "F"</td>
                <td>{totalSomaF} </td>
              </tr>
              <tr>
                <td>GALERIA "G"</td>
                <td>{totalSomaG} </td>
              </tr>
              <tr>
                <td>TRIAGEM</td>
                <td>{totalSomaTR} </td>
              </tr>
              <tr>
                <td> <b>TOTAL NA UP</b></td>
                <td><strong>{totalUp}</strong> </td>
              </tr>


              <tr>
                <td> DOMICILIAR</td>
                <td>{pessoasDomiciliartotal} </td>
              </tr>
              <tr>
                <td>HOSPITAL</td>
                <td>{pessoasHospitaltotal}</td>
              </tr>
              <tr>
                <td>PERNOITE</td>
                <td>{pessoasPernoitetotal}</td>
              </tr>
              <tr>
                <td> <b>TOTAL GERAL</b></td>
                <td><strong>{totalGeral}</strong></td>
              </tr>


            </tbody>
          </Table>
          <table style={{ width: '90%', borderCollapse: 'collapse', border: '1px solid #ddd', fontSize: '10px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">A</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">B</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">C</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">D</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">E</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">F</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">G - TR</th>
                

              </tr>
            </thead>

            <tbody>

              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A101</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A201</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B101</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C101</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D101</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E101</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F101</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG201total}</td>
                
              </tr>
               <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A102</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A202</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B102</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C102</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D102</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E102</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F102</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG202total}</td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A103</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A203</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B103</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C103</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D103</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E103</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F103</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG203total}</td>
                
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A104</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A204</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B104</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C104</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D104</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E104</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F104</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF204total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG204total}</td>
                
              
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A105</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A205</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B105</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C105</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D105</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E105</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F105</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF205total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG205total}</td>
                
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A106</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A206</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B106</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C106</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D106</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E106</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F106</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF206total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG206total}</td>
                
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A107</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A207</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B107</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C107</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D107</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E107</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F107</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF207total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG207total}</td>
                
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A108</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A208</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B108</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C108</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D108</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E108</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F108</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF208total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG208total}</td>
                
              
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A109</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A209</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B109</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C109</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D109</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E109</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F109</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF209total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG209total}</td>
                
          
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A110</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A210</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B110</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C110</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D110</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E110</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F110</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG210total}</td>
                
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A111</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A211</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B111</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C111</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D111</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E111</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F111</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF211total}</td>
                
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
               
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A112</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A212</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B112</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C112</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D112</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E112</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F112</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR201total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A113</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A213</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B113</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C113</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D113</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E113</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F113</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR202total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A114</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A214</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B114</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C114</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D114</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E114</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F114</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
             
                
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A115</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA115total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A215</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA215total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B115</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB115total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B215</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB215total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E115</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE115total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E215</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE215total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A116</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA116total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A216</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA216total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B116</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB116total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B216</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB216total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E116</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE116total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E216</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE216total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A117</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA117total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A217</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA217total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B117</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB117total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B217</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB217total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E117</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE117total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E217</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE217total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A118</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA118total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A218</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA218total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B118</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB118total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B218</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB218total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E118</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE118total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E218</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE218total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A119</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA119total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A219</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA219total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B119</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB119total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B219</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB219total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E119</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE119total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E219</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE219total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A120</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA120total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A220</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA220total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B120</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB120total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B220</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB220total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E120</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE120total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E220</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE220total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A121</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA121total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A221</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA221total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B121</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB121total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B221</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB221total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E121</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE121total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E221</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE221total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A122</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA122total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A222</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA222total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B122</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB122total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B222</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB222total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E122</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE122total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E222</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE222total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
              </tr>
             
             
            


            </tbody>
          </table>

        </div>

        </center>
      </div>}


      {(pagina === 1) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>GALERIA "A"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}
      {(pagina === 5) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CONTAGEM GERAL</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}
      {(pagina === 1 || pagina === 5) && <div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10, }}><b>GALERIA "A" / TOTAL GALERIA ({totalSomaA}) / QUADRANTE A101 a A112 ({totalQuadrante1A}) / QUADRANTE A113 a A122 ({totalQuadrante2A}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A101" celaTotal={pessoasSearchMapA101total} celaMap={(pessoasSearchMapA101 && pessoasSearchMapA101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A102" celaTotal={pessoasSearchMapA102total} celaMap={(pessoasSearchMapA102 && pessoasSearchMapA102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A103" celaTotal={pessoasSearchMapA103total} celaMap={(pessoasSearchMapA103 && pessoasSearchMapA103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A104" celaTotal={pessoasSearchMapA104total} celaMap={(pessoasSearchMapA104 && pessoasSearchMapA104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A105" celaTotal={pessoasSearchMapA105total} celaMap={(pessoasSearchMapA105 && pessoasSearchMapA105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A106" celaTotal={pessoasSearchMapA106total} celaMap={(pessoasSearchMapA106 && pessoasSearchMapA106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A107" celaTotal={pessoasSearchMapA107total} celaMap={(pessoasSearchMapA107 && pessoasSearchMapA107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A108" celaTotal={pessoasSearchMapA108total} celaMap={(pessoasSearchMapA108 && pessoasSearchMapA108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A109" celaTotal={pessoasSearchMapA109total} celaMap={(pessoasSearchMapA109 && pessoasSearchMapA109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A110" celaTotal={pessoasSearchMapA110total} celaMap={(pessoasSearchMapA110 && pessoasSearchMapA110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A111" celaTotal={pessoasSearchMapA111total} celaMap={(pessoasSearchMapA111 && pessoasSearchMapA111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A112" celaTotal={pessoasSearchMapA112total} celaMap={(pessoasSearchMapA112 && pessoasSearchMapA112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A113" celaTotal={pessoasSearchMapA113total} celaMap={(pessoasSearchMapA113 && pessoasSearchMapA113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A114" celaTotal={pessoasSearchMapA114total} celaMap={(pessoasSearchMapA114 && pessoasSearchMapA114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A115 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A115" celaTotal={pessoasSearchMapA115total} celaMap={(pessoasSearchMapA115 && pessoasSearchMapA115)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A116 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A116" celaTotal={pessoasSearchMapA116total} celaMap={(pessoasSearchMapA116 && pessoasSearchMapA116)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A117 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A117" celaTotal={pessoasSearchMapA117total} celaMap={(pessoasSearchMapA117 && pessoasSearchMapA117)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A118 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A118" celaTotal={pessoasSearchMapA118total} celaMap={(pessoasSearchMapA118 && pessoasSearchMapA118)} />
            </Col>
          </Row>
          <Row >
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A119 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A119" celaTotal={pessoasSearchMapA119total} celaMap={(pessoasSearchMapA119 && pessoasSearchMapA119)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A120 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A120" celaTotal={pessoasSearchMapA120total} celaMap={(pessoasSearchMapA120 && pessoasSearchMapA120)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A121 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A121" celaTotal={pessoasSearchMapA121total} celaMap={(pessoasSearchMapA121 && pessoasSearchMapA121)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A122 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A122" celaTotal={pessoasSearchMapA122total} celaMap={(pessoasSearchMapA122 && pessoasSearchMapA122)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>A101 - A112 <b> ( {totalQuadrante1A} ) </b></small></p>
              <p> <small>A113 - A122 <b> ( {totalQuadrante2A} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante2A + totalQuadrante1A} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 1 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "A" / TOTAL GALERIA ({totalSomaA}) / QUADRANTE A201 a A212 ({totalQuadrante3A}) / QUADRANTE A213 a A222 ({totalQuadrante4A}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 1 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A201" celaTotal={pessoasSearchMapA201total} celaMap={(pessoasSearchMapA201 && pessoasSearchMapA201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A202" celaTotal={pessoasSearchMapA202total} celaMap={(pessoasSearchMapA202 && pessoasSearchMapA202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A203" celaTotal={pessoasSearchMapA203total} celaMap={(pessoasSearchMapA203 && pessoasSearchMapA203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A204" celaTotal={pessoasSearchMapA204total} celaMap={(pessoasSearchMapA204 && pessoasSearchMapA204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A205" celaTotal={pessoasSearchMapA205total} celaMap={(pessoasSearchMapA205 && pessoasSearchMapA205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A206" celaTotal={pessoasSearchMapA206total} celaMap={(pessoasSearchMapA206 && pessoasSearchMapA206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A207" celaTotal={pessoasSearchMapA207total} celaMap={(pessoasSearchMapA207 && pessoasSearchMapA207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A208" celaTotal={pessoasSearchMapA208total} celaMap={(pessoasSearchMapA208 && pessoasSearchMapA208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A209" celaTotal={pessoasSearchMapA209total} celaMap={(pessoasSearchMapA209 && pessoasSearchMapA209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A210" celaTotal={pessoasSearchMapA210total} celaMap={(pessoasSearchMapA210 && pessoasSearchMapA210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A211" celaTotal={pessoasSearchMapA211total} celaMap={(pessoasSearchMapA211 && pessoasSearchMapA211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A212" celaTotal={pessoasSearchMapA212total} celaMap={(pessoasSearchMapA212 && pessoasSearchMapA212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A213" celaTotal={pessoasSearchMapA213total} celaMap={(pessoasSearchMapA213 && pessoasSearchMapA213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A214" celaTotal={pessoasSearchMapA214total} celaMap={(pessoasSearchMapA214 && pessoasSearchMapA214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A215 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A215" celaTotal={pessoasSearchMapA215total} celaMap={(pessoasSearchMapA215 && pessoasSearchMapA215)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A216 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A216" celaTotal={pessoasSearchMapA216total} celaMap={(pessoasSearchMapA216 && pessoasSearchMapA216)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A217 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A217" celaTotal={pessoasSearchMapA217total} celaMap={(pessoasSearchMapA217 && pessoasSearchMapA217)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A218 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A218" celaTotal={pessoasSearchMapA218total} celaMap={(pessoasSearchMapA218 && pessoasSearchMapA218)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A219 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A219" celaTotal={pessoasSearchMapA219total} celaMap={(pessoasSearchMapA219 && pessoasSearchMapA219)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A220 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A220" celaTotal={pessoasSearchMapA220total} celaMap={(pessoasSearchMapA220 && pessoasSearchMapA220)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A221 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A221" celaTotal={pessoasSearchMapA221total} celaMap={(pessoasSearchMapA221 && pessoasSearchMapA221)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>A222 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A222" celaTotal={pessoasSearchMapA222total} celaMap={(pessoasSearchMapA222 && pessoasSearchMapA222)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>A201 - A212 <b> ( {totalQuadrante3A} ) </b></small></p>
              <p> <small>A213 - A222 <b> ( {totalQuadrante4A} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante3A + totalQuadrante4A} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 2) && <p className={styles.pulolista}><center><h1 style={pagina === 2 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "B"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 2 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 2 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">


        <center> <small style={{ marginTop: -10 }}><b>GALERIA "B" / TOTAL GALERIA ({totalSomaB}) / QUADRANTE B101 a B112 ({totalQuadrante1B}) / QUADRANTE B113 a B122 ({totalQuadrante2B}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B101" celaTotal={pessoasSearchMapB101total} celaMap={(pessoasSearchMapB101 && pessoasSearchMapB101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B102" celaTotal={pessoasSearchMapB102total} celaMap={(pessoasSearchMapB102 && pessoasSearchMapB102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B103" celaTotal={pessoasSearchMapB103total} celaMap={(pessoasSearchMapB103 && pessoasSearchMapB103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B104" celaTotal={pessoasSearchMapB104total} celaMap={(pessoasSearchMapB104 && pessoasSearchMapB104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B105" celaTotal={pessoasSearchMapB105total} celaMap={(pessoasSearchMapB105 && pessoasSearchMapB105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B106" celaTotal={pessoasSearchMapB106total} celaMap={(pessoasSearchMapB106 && pessoasSearchMapB106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B107" celaTotal={pessoasSearchMapB107total} celaMap={(pessoasSearchMapB107 && pessoasSearchMapB107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B108" celaTotal={pessoasSearchMapB108total} celaMap={(pessoasSearchMapB108 && pessoasSearchMapB108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B109" celaTotal={pessoasSearchMapB109total} celaMap={(pessoasSearchMapB109 && pessoasSearchMapB109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B110" celaTotal={pessoasSearchMapB110total} celaMap={(pessoasSearchMapB110 && pessoasSearchMapB110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B111" celaTotal={pessoasSearchMapB111total} celaMap={(pessoasSearchMapB111 && pessoasSearchMapB111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B112" celaTotal={pessoasSearchMapB112total} celaMap={(pessoasSearchMapB112 && pessoasSearchMapB112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B113" celaTotal={pessoasSearchMapB113total} celaMap={(pessoasSearchMapB113 && pessoasSearchMapB113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B114" celaTotal={pessoasSearchMapB114total} celaMap={(pessoasSearchMapB114 && pessoasSearchMapB114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B115 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B115" celaTotal={pessoasSearchMapB115total} celaMap={(pessoasSearchMapB115 && pessoasSearchMapB115)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B116 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B116" celaTotal={pessoasSearchMapB116total} celaMap={(pessoasSearchMapB116 && pessoasSearchMapB116)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B117 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B117" celaTotal={pessoasSearchMapB117total} celaMap={(pessoasSearchMapB117 && pessoasSearchMapB117)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B118 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B118" celaTotal={pessoasSearchMapB118total} celaMap={(pessoasSearchMapB118 && pessoasSearchMapB118)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B119 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B119" celaTotal={pessoasSearchMapB119total} celaMap={(pessoasSearchMapB119 && pessoasSearchMapB119)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B120 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B120" celaTotal={pessoasSearchMapB120total} celaMap={(pessoasSearchMapB120 && pessoasSearchMapB120)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B121 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B121" celaTotal={pessoasSearchMapB121total} celaMap={(pessoasSearchMapB121 && pessoasSearchMapB121)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B122 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B122" celaTotal={pessoasSearchMapB122total} celaMap={(pessoasSearchMapB122 && pessoasSearchMapB122)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>B101 - B112 <b> ( {totalQuadrante1B} ) </b></small></p>
              <p> <small>B113 - B122 <b> ( {totalQuadrante2B} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante2B + totalQuadrante1B} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}

      {(pagina === 2 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "B" / TOTAL GALERIA ({totalSomaB}) / QUADRANTE B201 a B212 ({totalQuadrante3B}) / QUADRANTE B213 a B222 ({totalQuadrante4B}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 2 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B201" celaTotal={pessoasSearchMapB201total} celaMap={(pessoasSearchMapB201 && pessoasSearchMapB201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B202" celaTotal={pessoasSearchMapB202total} celaMap={(pessoasSearchMapB202 && pessoasSearchMapB202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B203" celaTotal={pessoasSearchMapB203total} celaMap={(pessoasSearchMapB203 && pessoasSearchMapB203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B204" celaTotal={pessoasSearchMapB204total} celaMap={(pessoasSearchMapB204 && pessoasSearchMapB204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B205" celaTotal={pessoasSearchMapB205total} celaMap={(pessoasSearchMapB205 && pessoasSearchMapB205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B206" celaTotal={pessoasSearchMapB206total} celaMap={(pessoasSearchMapB206 && pessoasSearchMapB206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B207" celaTotal={pessoasSearchMapB207total} celaMap={(pessoasSearchMapB207 && pessoasSearchMapB207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B208" celaTotal={pessoasSearchMapB208total} celaMap={(pessoasSearchMapB208 && pessoasSearchMapB208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B209" celaTotal={pessoasSearchMapB209total} celaMap={(pessoasSearchMapB209 && pessoasSearchMapB209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B210" celaTotal={pessoasSearchMapB210total} celaMap={(pessoasSearchMapB210 && pessoasSearchMapB210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B211" celaTotal={pessoasSearchMapB211total} celaMap={(pessoasSearchMapB211 && pessoasSearchMapB211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B212" celaTotal={pessoasSearchMapB212total} celaMap={(pessoasSearchMapB212 && pessoasSearchMapB212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B213" celaTotal={pessoasSearchMapB213total} celaMap={(pessoasSearchMapB213 && pessoasSearchMapB213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B214" celaTotal={pessoasSearchMapB214total} celaMap={(pessoasSearchMapB214 && pessoasSearchMapB214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B215 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B215" celaTotal={pessoasSearchMapB215total} celaMap={(pessoasSearchMapB215 && pessoasSearchMapB215)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B216 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B216" celaTotal={pessoasSearchMapB216total} celaMap={(pessoasSearchMapB216 && pessoasSearchMapB216)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B217 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B217" celaTotal={pessoasSearchMapB217total} celaMap={(pessoasSearchMapB217 && pessoasSearchMapB217)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B218 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B218" celaTotal={pessoasSearchMapB218total} celaMap={(pessoasSearchMapB218 && pessoasSearchMapB218)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B219 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B219" celaTotal={pessoasSearchMapB219total} celaMap={(pessoasSearchMapB219 && pessoasSearchMapB219)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B220 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B220" celaTotal={pessoasSearchMapB220total} celaMap={(pessoasSearchMapB220 && pessoasSearchMapB220)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B221 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B221" celaTotal={pessoasSearchMapB221total} celaMap={(pessoasSearchMapB221 && pessoasSearchMapB221)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B222 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B222" celaTotal={pessoasSearchMapB222total} celaMap={(pessoasSearchMapB222 && pessoasSearchMapB222)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>B201 - B212 <b> ( {totalQuadrante3B} ) </b></small></p>
              <p> <small>B213 - B222 <b> ( {totalQuadrante4B} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante3B + totalQuadrante4B} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 3) && <p className={styles.pulolista}><center><h1 style={pagina === 3 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "C"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 3 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 3 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "C" / TOTAL GALERIA ({totalSomaC}) / QUADRANTE C101 a C114 ({totalQuadrante1C}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C101" celaTotal={pessoasSearchMapC101total} celaMap={(pessoasSearchMapC101 && pessoasSearchMapC101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C102" celaTotal={pessoasSearchMapC102total} celaMap={(pessoasSearchMapC102 && pessoasSearchMapC102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C103" celaTotal={pessoasSearchMapC103total} celaMap={(pessoasSearchMapC103 && pessoasSearchMapC103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C104" celaTotal={pessoasSearchMapC104total} celaMap={(pessoasSearchMapC104 && pessoasSearchMapC104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C105" celaTotal={pessoasSearchMapC105total} celaMap={(pessoasSearchMapC105 && pessoasSearchMapC105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C106" celaTotal={pessoasSearchMapC106total} celaMap={(pessoasSearchMapC106 && pessoasSearchMapC106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C107" celaTotal={pessoasSearchMapC107total} celaMap={(pessoasSearchMapC107 && pessoasSearchMapC107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C108" celaTotal={pessoasSearchMapC108total} celaMap={(pessoasSearchMapC108 && pessoasSearchMapC108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C109" celaTotal={pessoasSearchMapC109total} celaMap={(pessoasSearchMapC109 && pessoasSearchMapC109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C110" celaTotal={pessoasSearchMapC110total} celaMap={(pessoasSearchMapC110 && pessoasSearchMapC110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C111" celaTotal={pessoasSearchMapC111total} celaMap={(pessoasSearchMapC111 && pessoasSearchMapC111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C112" celaTotal={pessoasSearchMapC112total} celaMap={(pessoasSearchMapC112 && pessoasSearchMapC112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C113" celaTotal={pessoasSearchMapC113total} celaMap={(pessoasSearchMapC113 && pessoasSearchMapC113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C114" celaTotal={pessoasSearchMapC114total} celaMap={(pessoasSearchMapC114 && pessoasSearchMapC114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
              
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>C101 - C114 <b> ( {totalQuadrante1C} ) </b></small></p>
              

            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 3 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "C" / TOTAL GALERIA ({totalSomaC}) / QUADRANTE C201 a C214 ({totalQuadrante3C}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 3 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C201" celaTotal={pessoasSearchMapC201total} celaMap={(pessoasSearchMapC201 && pessoasSearchMapC201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C202" celaTotal={pessoasSearchMapC202total} celaMap={(pessoasSearchMapC202 && pessoasSearchMapC202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C203" celaTotal={pessoasSearchMapC203total} celaMap={(pessoasSearchMapC203 && pessoasSearchMapC203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C204" celaTotal={pessoasSearchMapC204total} celaMap={(pessoasSearchMapC204 && pessoasSearchMapC204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C205" celaTotal={pessoasSearchMapC205total} celaMap={(pessoasSearchMapC205 && pessoasSearchMapC205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C206" celaTotal={pessoasSearchMapC206total} celaMap={(pessoasSearchMapC206 && pessoasSearchMapC206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C207" celaTotal={pessoasSearchMapC207total} celaMap={(pessoasSearchMapC207 && pessoasSearchMapC207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C208" celaTotal={pessoasSearchMapC208total} celaMap={(pessoasSearchMapC208 && pessoasSearchMapC208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C209" celaTotal={pessoasSearchMapC209total} celaMap={(pessoasSearchMapC209 && pessoasSearchMapC209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C210" celaTotal={pessoasSearchMapC210total} celaMap={(pessoasSearchMapC210 && pessoasSearchMapC210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C211" celaTotal={pessoasSearchMapC211total} celaMap={(pessoasSearchMapC211 && pessoasSearchMapC211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C212" celaTotal={pessoasSearchMapC212total} celaMap={(pessoasSearchMapC212 && pessoasSearchMapC212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C213" celaTotal={pessoasSearchMapC213total} celaMap={(pessoasSearchMapC213 && pessoasSearchMapC213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C214" celaTotal={pessoasSearchMapC214total} celaMap={(pessoasSearchMapC214 && pessoasSearchMapC214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
              
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>C201 - C214 <b> ( {totalQuadrante3C} ) </b></small></p>
              



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 4) &&

        <p className={styles.pulolista}><center>

          <h1 style={pagina === 4 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -80, fontFamily: 'policiapenal' }}>

            GALERIA "D"

          </h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
          </Button>

        </center>
        </p>}

      {(pagina === 4 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 4 ? { marginTop: 0 } : { marginTop: -90 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

<center> <small style={{ marginTop: -10 }}><b>GALERIA "D" / TOTAL GALERIA ({totalSomaD}) / QUADRANTE D101 a D114 ({totalQuadrante1C}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
</center>

<Container style={{ width: '320mm', height: '210mm' }}>
  {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D101" celaTotal={pessoasSearchMapD101total} celaMap={(pessoasSearchMapD101 && pessoasSearchMapD101)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="C102" celaTotal={pessoasSearchMapD102total} celaMap={(pessoasSearchMapD102 && pessoasSearchMapD102)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D103" celaTotal={pessoasSearchMapD103total} celaMap={(pessoasSearchMapD103 && pessoasSearchMapD103)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D104" celaTotal={pessoasSearchMapD104total} celaMap={(pessoasSearchMapD104 && pessoasSearchMapD104)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D105" celaTotal={pessoasSearchMapD105total} celaMap={(pessoasSearchMapD105 && pessoasSearchMapD105)} />


    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D106" celaTotal={pessoasSearchMapD106total} celaMap={(pessoasSearchMapD106 && pessoasSearchMapD106)} />

    </Col>

  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D107" celaTotal={pessoasSearchMapD107total} celaMap={(pessoasSearchMapD107 && pessoasSearchMapD107)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="D108" celaTotal={pessoasSearchMapD108total} celaMap={(pessoasSearchMapD108 && pessoasSearchMapD108)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="D109" celaTotal={pessoasSearchMapD109total} celaMap={(pessoasSearchMapD109 && pessoasSearchMapD109)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="D110" celaTotal={pessoasSearchMapD110total} celaMap={(pessoasSearchMapD110 && pessoasSearchMapD110)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D111" celaTotal={pessoasSearchMapD111total} celaMap={(pessoasSearchMapD111 && pessoasSearchMapD111)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D112" celaTotal={pessoasSearchMapD112total} celaMap={(pessoasSearchMapD112 && pessoasSearchMapD112)} />
    </Col>

  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D113" celaTotal={pessoasSearchMapD113total} celaMap={(pessoasSearchMapD113 && pessoasSearchMapD113)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D114" celaTotal={pessoasSearchMapD114total} celaMap={(pessoasSearchMapD114 && pessoasSearchMapD114)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
     
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
      
    </Col>
  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
      <p><small>D101 - D114 <b> ( {totalQuadrante1D} ) </b></small></p>
      

    </Col>

  </Row>



</Container>


</div>}
{(pagina === 4 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

<center> <small style={{ marginTop: -10 }}><b>GALERIA "D" / TOTAL GALERIA ({totalSomaC}) / QUADRANTE D201 a D214 ({totalQuadrante3D}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
</center>

<Container style={pagina === 4 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
  {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D201" celaTotal={pessoasSearchMapD201total} celaMap={(pessoasSearchMapD201 && pessoasSearchMapD201)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D202" celaTotal={pessoasSearchMapD202total} celaMap={(pessoasSearchMapD202 && pessoasSearchMapD202)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D203" celaTotal={pessoasSearchMapD203total} celaMap={(pessoasSearchMapD203 && pessoasSearchMapD203)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D204" celaTotal={pessoasSearchMapD204total} celaMap={(pessoasSearchMapD204 && pessoasSearchMapD204)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D205" celaTotal={pessoasSearchMapD205total} celaMap={(pessoasSearchMapD205 && pessoasSearchMapD205)} />


    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D206" celaTotal={pessoasSearchMapD206total} celaMap={(pessoasSearchMapD206 && pessoasSearchMapD206)} />

    </Col>

  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D207" celaTotal={pessoasSearchMapD207total} celaMap={(pessoasSearchMapD207 && pessoasSearchMapD207)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="D208" celaTotal={pessoasSearchMapD208total} celaMap={(pessoasSearchMapD208 && pessoasSearchMapD208)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="D209" celaTotal={pessoasSearchMapD209total} celaMap={(pessoasSearchMapD209 && pessoasSearchMapD209)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="D210" celaTotal={pessoasSearchMapD210total} celaMap={(pessoasSearchMapD210 && pessoasSearchMapD210)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D211" celaTotal={pessoasSearchMapD211total} celaMap={(pessoasSearchMapD211 && pessoasSearchMapD211)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D212" celaTotal={pessoasSearchMapD212total} celaMap={(pessoasSearchMapD212 && pessoasSearchMapD212)} />
    </Col>

  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D213" celaTotal={pessoasSearchMapD213total} celaMap={(pessoasSearchMapD213 && pessoasSearchMapD213)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>D214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="D214" celaTotal={pessoasSearchMapD214total} celaMap={(pessoasSearchMapD214 && pessoasSearchMapD214)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
     
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
      
    </Col>
  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
      <p><small>D201 - D214 <b> ( {totalQuadrante3D} ) </b></small></p>
      



    </Col>

  </Row>



</Container>


</div>}
{(pagina === 7) &&

<p className={styles.pulolista}><center>

  <h1 style={pagina === 7 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -80, fontFamily: 'policiapenal' }}>

    GALERIA "E"

  </h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
  </Button>

</center>
</p>}
{(pagina === 7 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 7 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10, }}><b>GALERIA "E" / TOTAL GALERIA ({totalSomaE}) / QUADRANTE E101 a E112 ({totalQuadrante1E}) / QUADRANTE E113 a E122 ({totalQuadrante2A}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E101" celaTotal={pessoasSearchMapE101total} celaMap={(pessoasSearchMapE101 && pessoasSearchMapE101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E102" celaTotal={pessoasSearchMapE102total} celaMap={(pessoasSearchMapE102 && pessoasSearchMapE102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E103" celaTotal={pessoasSearchMapE103total} celaMap={(pessoasSearchMapE103 && pessoasSearchMapE103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E104" celaTotal={pessoasSearchMapE104total} celaMap={(pessoasSearchMapE104 && pessoasSearchMapE104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E105" celaTotal={pessoasSearchMapE105total} celaMap={(pessoasSearchMapE105 && pessoasSearchMapE105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E106" celaTotal={pessoasSearchMapE106total} celaMap={(pessoasSearchMapE106 && pessoasSearchMapE106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E107" celaTotal={pessoasSearchMapE107total} celaMap={(pessoasSearchMapE107 && pessoasSearchMapE107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E108" celaTotal={pessoasSearchMapE108total} celaMap={(pessoasSearchMapE108 && pessoasSearchMapE108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E109" celaTotal={pessoasSearchMapE109total} celaMap={(pessoasSearchMapE109 && pessoasSearchMapE109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E110" celaTotal={pessoasSearchMapE110total} celaMap={(pessoasSearchMapE110 && pessoasSearchMapE110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E111" celaTotal={pessoasSearchMapE111total} celaMap={(pessoasSearchMapE111 && pessoasSearchMapE111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E112" celaTotal={pessoasSearchMapE112total} celaMap={(pessoasSearchMapE112 && pessoasSearchMapE112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E113" celaTotal={pessoasSearchMapE113total} celaMap={(pessoasSearchMapE113 && pessoasSearchMapE113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E114" celaTotal={pessoasSearchMapE114total} celaMap={(pessoasSearchMapE114 && pessoasSearchMapE114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E115 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E115" celaTotal={pessoasSearchMapE115total} celaMap={(pessoasSearchMapE115 && pessoasSearchMapE115)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E116 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E116" celaTotal={pessoasSearchMapE116total} celaMap={(pessoasSearchMapE116 && pessoasSearchMapE116)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E117 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E117" celaTotal={pessoasSearchMapE117total} celaMap={(pessoasSearchMapE117 && pessoasSearchMapE117)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E118 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E118" celaTotal={pessoasSearchMapE118total} celaMap={(pessoasSearchMapE118 && pessoasSearchMapE118)} />
            </Col>
          </Row>
          <Row >
          <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E119 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E119" celaTotal={pessoasSearchMapE119total} celaMap={(pessoasSearchMapE119 && pessoasSearchMapE119)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E120 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E120" celaTotal={pessoasSearchMapE120total} celaMap={(pessoasSearchMapE120 && pessoasSearchMapE120)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E121 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E121" celaTotal={pessoasSearchMapE121total} celaMap={(pessoasSearchMapE121 && pessoasSearchMapE121)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E122 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E122" celaTotal={pessoasSearchMapE122total} celaMap={(pessoasSearchMapE122 && pessoasSearchMapE122)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>E101 - E112 <b> ( {totalQuadrante1E} ) </b></small></p>
              <p> <small>E113 - E122 <b> ( {totalQuadrante2E} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante2E + totalQuadrante1E} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 7 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "E" / TOTAL GALERIA ({totalSomaE}) / QUADRANTE E201 a E212 ({totalQuadrante3E}) / QUADRANTE E213 a E222 ({totalQuadrante4E}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 7 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E201" celaTotal={pessoasSearchMapE201total} celaMap={(pessoasSearchMapE201 && pessoasSearchMapE201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E202" celaTotal={pessoasSearchMapE202total} celaMap={(pessoasSearchMapE202 && pessoasSearchMapE202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E203" celaTotal={pessoasSearchMapE203total} celaMap={(pessoasSearchMapE203 && pessoasSearchMapE203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E204" celaTotal={pessoasSearchMapE204total} celaMap={(pessoasSearchMapE204 && pessoasSearchMapE204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E205" celaTotal={pessoasSearchMapE205total} celaMap={(pessoasSearchMapE205 && pessoasSearchMapE205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E206" celaTotal={pessoasSearchMapE206total} celaMap={(pessoasSearchMapE206 && pessoasSearchMapE206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E207" celaTotal={pessoasSearchMapE207total} celaMap={(pessoasSearchMapE207 && pessoasSearchMapE207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E208" celaTotal={pessoasSearchMapE208total} celaMap={(pessoasSearchMapE208 && pessoasSearchMapE208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E209" celaTotal={pessoasSearchMapE209total} celaMap={(pessoasSearchMapE209 && pessoasSearchMapE209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E210" celaTotal={pessoasSearchMapE210total} celaMap={(pessoasSearchMapE210 && pessoasSearchMapE210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E211" celaTotal={pessoasSearchMapE211total} celaMap={(pessoasSearchMapE211 && pessoasSearchMapE211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E212" celaTotal={pessoasSearchMapE212total} celaMap={(pessoasSearchMapE212 && pessoasSearchMapE212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E213" celaTotal={pessoasSearchMapE213total} celaMap={(pessoasSearchMapE213 && pessoasSearchMapE213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E214" celaTotal={pessoasSearchMapE214total} celaMap={(pessoasSearchMapE214 && pessoasSearchMapE214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E215 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E215" celaTotal={pessoasSearchMapE215total} celaMap={(pessoasSearchMapE215 && pessoasSearchMapE215)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E216 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E216" celaTotal={pessoasSearchMapE216total} celaMap={(pessoasSearchMapE216 && pessoasSearchMapE216)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E217 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E217" celaTotal={pessoasSearchMapE217total} celaMap={(pessoasSearchMapE217 && pessoasSearchMapE217)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E218 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E218" celaTotal={pessoasSearchMapE218total} celaMap={(pessoasSearchMapE218 && pessoasSearchMapE218)} />
            </Col>
          </Row>
          <Row>
          <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>E219 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E219" celaTotal={pessoasSearchMapE219total} celaMap={(pessoasSearchMapE219 && pessoasSearchMapE219)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>E220 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E220" celaTotal={pessoasSearchMapE220total} celaMap={(pessoasSearchMapE220 && pessoasSearchMapE220)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>E221 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E221" celaTotal={pessoasSearchMapE221total} celaMap={(pessoasSearchMapE221 && pessoasSearchMapE221)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>E222 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E222" celaTotal={pessoasSearchMapE222total} celaMap={(pessoasSearchMapE222 && pessoasSearchMapE222)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>E201 - E212 <b> ( {totalQuadrante3E} ) </b></small></p>
              <p> <small>E213 - E222 <b> ( {totalQuadrante4E} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante3E + totalQuadrante4E} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}

      {(pagina === 8) &&

<p className={styles.pulolista}><center>

  <h1 style={pagina === 8 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -90, fontFamily: 'policiapenal' }}>

    GALERIA "F"

  </h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
  </Button>

</center>
</p>}

{(pagina === 8 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 8 ? { marginTop: 0 } : { marginTop: -90 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

<center> <small style={{ marginTop: -10 }}><b>GALERIA "F" / TOTAL GALERIA ({totalSomaF}) / QUADRANTE F101 a F114 ({totalQuadrante1C}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
</center>

<Container style={{ width: '320mm', height: '210mm' }}>
{/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F101" celaTotal={pessoasSearchMapF101total} celaMap={(pessoasSearchMapF101 && pessoasSearchMapF101)} />
</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="C102" celaTotal={pessoasSearchMapF102total} celaMap={(pessoasSearchMapF102 && pessoasSearchMapF102)} />
</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F103" celaTotal={pessoasSearchMapF103total} celaMap={(pessoasSearchMapF103 && pessoasSearchMapF103)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F104" celaTotal={pessoasSearchMapF104total} celaMap={(pessoasSearchMapF104 && pessoasSearchMapF104)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F105" celaTotal={pessoasSearchMapF105total} celaMap={(pessoasSearchMapF105 && pessoasSearchMapF105)} />


</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F106" celaTotal={pessoasSearchMapF106total} celaMap={(pessoasSearchMapF106 && pessoasSearchMapF106)} />

</Col>

</Row>
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F107" celaTotal={pessoasSearchMapF107total} celaMap={(pessoasSearchMapF107 && pessoasSearchMapF107)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

<Celacontagem cela="F108" celaTotal={pessoasSearchMapF108total} celaMap={(pessoasSearchMapF108 && pessoasSearchMapF108)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

<Celacontagem cela="F109" celaTotal={pessoasSearchMapF109total} celaMap={(pessoasSearchMapF109 && pessoasSearchMapF109)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

<Celacontagem cela="F110" celaTotal={pessoasSearchMapF110total} celaMap={(pessoasSearchMapF110 && pessoasSearchMapF110)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F111" celaTotal={pessoasSearchMapF111total} celaMap={(pessoasSearchMapF111 && pessoasSearchMapF111)} />
</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F112" celaTotal={pessoasSearchMapF112total} celaMap={(pessoasSearchMapF112 && pessoasSearchMapF112)} />
</Col>

</Row>
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F113" celaTotal={pessoasSearchMapF113total} celaMap={(pessoasSearchMapF113 && pessoasSearchMapF113)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F114" celaTotal={pessoasSearchMapF114total} celaMap={(pessoasSearchMapF114 && pessoasSearchMapF114)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>


</Col>
</Row>
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
<p><small>F101 - F114 <b> ( {totalQuadrante1F} ) </b></small></p>


</Col>

</Row>



</Container>


</div>}
{(pagina === 8 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

<center> <small style={{ marginTop: -10 }}><b>GALERIA "F" / TOTAL GALERIA ({totalSomaF}) / QUADRANTE F201 a F214 ({totalQuadrante3F}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
</center>

<Container style={pagina === 8 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
{/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F201" celaTotal={pessoasSearchMapF201total} celaMap={(pessoasSearchMapF201 && pessoasSearchMapF201)} />
</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F202" celaTotal={pessoasSearchMapF202total} celaMap={(pessoasSearchMapF202 && pessoasSearchMapF202)} />
</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F203" celaTotal={pessoasSearchMapF203total} celaMap={(pessoasSearchMapF203 && pessoasSearchMapF203)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F204" celaTotal={pessoasSearchMapF204total} celaMap={(pessoasSearchMapF204 && pessoasSearchMapF204)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F205" celaTotal={pessoasSearchMapF205total} celaMap={(pessoasSearchMapF205 && pessoasSearchMapF205)} />


</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F206" celaTotal={pessoasSearchMapF206total} celaMap={(pessoasSearchMapF206 && pessoasSearchMapF206)} />

</Col>

</Row>
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F207" celaTotal={pessoasSearchMapF207total} celaMap={(pessoasSearchMapF207 && pessoasSearchMapF207)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

<Celacontagem cela="F208" celaTotal={pessoasSearchMapF208total} celaMap={(pessoasSearchMapF208 && pessoasSearchMapF208)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

<Celacontagem cela="F209" celaTotal={pessoasSearchMapF209total} celaMap={(pessoasSearchMapF209 && pessoasSearchMapF209)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

<Celacontagem cela="F210" celaTotal={pessoasSearchMapF210total} celaMap={(pessoasSearchMapF210 && pessoasSearchMapF210)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F211" celaTotal={pessoasSearchMapF211total} celaMap={(pessoasSearchMapF211 && pessoasSearchMapF211)} />
</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F212" celaTotal={pessoasSearchMapF212total} celaMap={(pessoasSearchMapF212 && pessoasSearchMapF212)} />
</Col>

</Row>
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F213" celaTotal={pessoasSearchMapF213total} celaMap={(pessoasSearchMapF213 && pessoasSearchMapF213)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
<center><b>F214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
<Celacontagem cela="F214" celaTotal={pessoasSearchMapF214total} celaMap={(pessoasSearchMapF214 && pessoasSearchMapF214)} />

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>


</Col>
</Row>
<Row>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
<Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
<p><small>F201 - F214 <b> ( {totalQuadrante3F} ) </b></small></p>




</Col>

</Row>



</Container>


</div>}
{(pagina === 9) &&

<p className={styles.pulolista}><center>

  <h1 style={pagina === 9 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -80, fontFamily: 'policiapenal' }}>

    GALERIA "G" E "TR"

  </h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
  </Button>

</center>
</p>}

{(pagina === 9 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 9 ? { marginTop: 0 } : { marginTop: -100 }}
id="conteudo-para-impressao" name="conteudo-para-impressao">

<center> <small style={{ marginTop: -10, marginBottom: -50 }}><b>GALERIA "G" E "TR" / TOTAL GALERIA "G" ({totalSomaG}) / TOTAL TRIAGEM ({totalSomaTR}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
</center>

<Container style={pagina === 9 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '180mm' }}>
  {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G201" celaTotal={pessoasSearchMapG201total} celaMap={(pessoasSearchMapG201 && pessoasSearchMapG201)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G202" celaTotal={pessoasSearchMapG202total} celaMap={(pessoasSearchMapG202 && pessoasSearchMapG202)} />
    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G203" celaTotal={pessoasSearchMapG203total} celaMap={(pessoasSearchMapG203 && pessoasSearchMapG203)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G204" celaTotal={pessoasSearchMapG204total} celaMap={(pessoasSearchMapG204 && pessoasSearchMapG204)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G205" celaTotal={pessoasSearchMapG205total} celaMap={(pessoasSearchMapG205 && pessoasSearchMapG205)} />


    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G206" celaTotal={pessoasSearchMapG206total} celaMap={(pessoasSearchMapG206 && pessoasSearchMapG206)} />

    </Col>

  </Row>
  <Row>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="G207" celaTotal={pessoasSearchMapG207total} celaMap={(pessoasSearchMapG207 && pessoasSearchMapG207)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="G208" celaTotal={pessoasSearchMapG208total} celaMap={(pessoasSearchMapG208 && pessoasSearchMapG208)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="G209" celaTotal={pessoasSearchMapG209total} celaMap={(pessoasSearchMapG209 && pessoasSearchMapG209)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
      <center><b>G210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

      <Celacontagem cela="G210" celaTotal={pessoasSearchMapG210total} celaMap={(pessoasSearchMapG210 && pessoasSearchMapG210)} />

    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>



    </Col>
    <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>



    </Col>


  </Row>
  <Row>


    <Col style={{ width: '28mm', height: '85mm', border: '1px solid black', backgroundColor: 'white', marginBottom: 0 }}>
      <center><b>TR201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="TR201" celaTotal={pessoasSearchMapTR201total} celaMap={(pessoasSearchMapTR201 && pessoasSearchMapTR201)} />

    </Col>
    <Col style={{ width: '28mm', height: '85mm', border: '1px solid black', backgroundColor: 'white', marginBottom: 0 }}>
      <center><b>TR202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
      <Celacontagem cela="TR202" celaTotal={pessoasSearchMapTR202total} celaMap={(pessoasSearchMapTR202 && pessoasSearchMapTR202)} />

    </Col>
    <Col style={{ width: '56mm', height: '85mm', border: '1px solid black', backgroundColor: 'white', marginBottom: 0 }}>
      <p> <small>G201 - G210 <b> ( {totalSomaG} ) </b></small></p>
      <p> <small>TRIAGEM &nbsp;&nbsp;&nbsp;&nbsp; <b> ( {pessoasSearchMapTR202total + pessoasSearchMapTR201total} ) </b></small></p>
    </Col>
  </Row>
</Container>


</div>}


    </>

  )
}

export default Contagem